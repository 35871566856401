import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { LoginService } from '../services/login.service';
import { log } from './decorators/log.decorator';
import { Contact } from '../structures/contact';

interface Setting {
    key: string;
    value: string;
    description: string;
    setting_type: string;
    metadata: any;
}

@Injectable({
    providedIn: 'root'
})
export class YpareoService {
    private _settings: Setting[] = [];

    /**
     * Fetches settings for a given structure ID from the API.
     * @param structureId - The ID of the structure to fetch settings for.
     * @returns An observable containing the settings for the specified structure.
     */
    @log() fetchSettings(structureId: number): Observable<Setting[]> {
        return this.http.get<Setting[]>(`/ypareo/settings/${structureId}`);
    }

    constructor(private loginService: LoginService, private http: HttpClient) {}

    /**
     * Simulates a test for importing into Ypareo.
     * This function returns an Observable emitting an object
     * that indicates whether the import was successful or not.
     *
     * @returns {Observable<{ success: boolean; error?: string }>}
     * An Observable that emits the result of the test.
     */
    testYpareoImport(): Observable<{ success: boolean; error?: string }> {
        // @todo: Ajouter le WS de test (n'existe pas encore). Suivre l'évolution de https://tasks.internal.cblue.be/issues/770877
        return new Observable((observer) => {
            // Simulation
            const simulatedData = {
                success: true
            };

            observer.next(simulatedData);
            observer.complete();
        });
    }

    /**
     * Saves settings for a given structure ID to the API.
     * @param structureId - The ID of the structure to save settings for.
     * @param settings - The settings to be saved.
     * @returns An observable of the HTTP response.
     */
    saveSettings(structureId: number, settings: object): Observable<any> {
        return this.http.post(`/ypareo/settings/${structureId}`, settings);
    }
}
