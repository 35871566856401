import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfigService } from 'src/app/services/config.service';
import { LoginService } from 'src/app/services/login.service';

/**
 *  fenetre de dialogue pour la deconnexion.
 */
@Component({
    selector: 'app-azure-login',
    templateUrl: './azure-login.component.html',
    styleUrls: ['./azure-login.component.scss'],
    standalone: false
})
export class AzureLoginComponent {
    constructor(
        public dialogRef: MatDialogRef<AzureLoginComponent>,
        public configService: ConfigService,
        private loginService: LoginService
    ) {}

    openAzureLogin() {
        window.addEventListener('message', (event) => {
            if (event.data.endOfAzure) {
                this.closeDialog(true);
            }
        });
        window.open(
            `${this.configService.getEndPoint()}/azure/login/azure`,
            '',
            'left=100,top=100,width=600,height=320'
        );
    }

    removeAzureForm() {
        this.loginService.removeAzureForm().subscribe(() => {
            this.closeDialog(false);
        });
    }

    getMSLogo() {
        return './assets/img/logo_ms.png';
    }

    /**
     * Ferme la fenêtre de création de cloud
     */
    closeDialog(status) {
        this.dialogRef.close(status);
    }
}
