import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { UsersService } from 'src/app/services/users.service';
import { LoginService } from 'src/app/services/login.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { DialogService } from 'src/app/services/dialog.service';

import { Role } from 'src/app/structures/role';
import { User } from 'src/app/structures/user';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    standalone: false
})
export class UserComponent {
    constructor(
        private usersService: UsersService,
        private dialogService: DialogService,
        private loginService: LoginService,
        private flashMessageService: FlashMessageService
    ) {}

    @Input() user: User;
    @Input() roles: Role[];
    @Input() additionnalRoles: Role[];

    openUserDialog(user: User): void {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage(
            {
                viewUser: user.id
            },
            '*'
        );
    }

    enableUser(user: User) {
        this.dialogService
            .openConfirmationDialog(
                `Activer l\'utilisateur`,
                `Êtes-vous sûr de vouloir activer l'utilisateur ${user.lastname.toUpperCase()} ${
                    user.firstname
                }?`
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.usersService.enableUser(user).subscribe(() => {
                        user.enabled = true;
                        this.flashMessageService.flash(
                            `<strong>${user.lastname.toUpperCase()} ${
                                user.firstname
                            }</strong> a été activé.`
                        );
                    });
                }
            });
    }

    disableUser(user: User) {
        this.dialogService
            .openConfirmationDialog(
                `Désactiver l\'utilisateur`,
                `Êtes-vous sûr de vouloir désactiver l'utilisateur ${user.lastname.toUpperCase()} ${
                    user.firstname
                }?`
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.usersService.disableUser(user).subscribe(() => {
                        user.enabled = false;
                        this.flashMessageService.flash(
                            `<strong>${user.lastname.toUpperCase()} ${
                                user.firstname
                            }</strong> a été désactivé.`
                        );
                    });
                }
            });
    }

    hasReminder() {
        return !!this.user.cgu_validation_reminder_mail;
    }

    hasIncompleteEvents(user: User): boolean {
        return !user.events.CGU || !user.events.emailverified || !user.events.passwordverified;
    }

    isDateInvalid() {
        return (
            (this.user.startaccount &&
                new Date(this.user.startaccount).getTime() > new Date().getTime()) ||
            (this.user.endaccount &&
                new Date(this.user.endaccount).getTime() < new Date().getTime())
        );
    }

    getDateClass() {
        if (!this.user.enabled) {
            return 'grey';
        } else {
            if (this.isDateInvalid()) {
                return 'orange';
            } else {
                return 'green';
            }
        }
    }

    getUserTooltip() {
        if (!this.user.enabled) {
            return `Cet utilisateur ne peut pas se connecter et n'est pas visible par les formateurs`;
        } else {
            if (this.isDateInvalid()) {
                return 'Cet utilisateur ne peut pas se connecter mais est visible par les formateurs';
            }
            return 'Cet utilisateur peut se connecter et est visible par les formateurs';
        }
    }

    showDateIcon() {
        return this.user.startaccount || this.user.endaccount;
    }

    getDateTooltip() {
        if (this.user.startaccount && !this.user.endaccount) {
            return `Utilisateur actif à partir du ${new Date(
                this.user.startaccount
            ).toLocaleDateString()}`;
        } else if (!this.user.startaccount && this.user.endaccount) {
            return `Utilisateur actif jusqu'au ${new Date(
                this.user.endaccount
            ).toLocaleDateString()}`;
        } else if (this.user.startaccount && this.user.endaccount) {
            return `Utilisateur actif du ${new Date(
                this.user.startaccount
            ).toLocaleDateString()} au ${new Date(this.user.endaccount).toLocaleDateString()} `;
        }
    }

    loginAs(user: User): void {
        this.dialogService
            .openConfirmationDialog(
                `S'identifier en tant que`,
                `Êtes-vous sûr de vouloir vous identifier en tant que <strong>${user.lastname.toUpperCase()} ${
                    user.firstname
                }</strong>?`
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.loginService.loginAs(user).subscribe();
                }
            });
    }

    canLoginAs(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }
}
