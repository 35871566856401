import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { Subscription } from 'rxjs';
import { News } from 'src/app/structures/news';
import { HttpEventType } from '@angular/common/http';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
    standalone: false
})
export class UploadFileComponent implements OnInit {
    file: File = null;
    loading = false;
    uploadProgress: number;
    subscriptions$: Subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<UploadFileComponent>,
        public uploadFilesService: UploadFilesService,
        @Inject(MAT_DIALOG_DATA)
        private data: News
    ) {}

    ngOnInit(): void {}

    /**********
     * GETTERS
     **********/
    getFileName(): string {
        if (this.file) {
            return this.file.name;
        } else {
            return '...';
        }
    }

    getUploadProgress(): number {
        return this.uploadProgress;
    }

    enabledUploadFileButton(): boolean {
        return !!this.file;
    }

    fileChangeEvent(event: any): void {
        this.file = event.target.files[0];
    }

    /**********
     * ACTIONS
     **********/

    uploadFile() {
        if (!this.loading && !!this.file) {
            this.loading = true;
            this.uploadProgress = 0;
            this.subscriptions$.add(
                this.uploadFilesService.uploadNewsImage(this.file, this.data).subscribe((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.uploadProgress = Math.round((100 * event.loaded) / event.total);
                    } else if (event.type === HttpEventType.Response) {
                        this.dialogRef.close(event.body);
                    }
                })
            );
        }
    }

    close() {
        this.dialogRef.close();
    }
}
