import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from 'src/app/structures/role';
import { User } from 'src/app/structures/user';

@Component({
    selector: 'app-rights-exceptions',
    templateUrl: './rights-exceptions.component.html',
    styleUrls: ['./rights-exceptions.component.scss'],
    standalone: false
})
export class RightsExceptionsComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            application: any;
            role: Role;
        },
        public dialogRef: MatDialogRef<RightsExceptionsComponent>
    ) {}

    searchTerm = '';
    usersList: User[];

    ngOnInit() {
        this.setUsersList();
    }

    /**
     * Crée la liste d'utilisateurs avec exception, en filtrant par rôle le cas échéant
     */
    setUsersList(): void {
        if (this.data.role) {
            this.usersList = [
                ...this.data.application.users.filter(
                    (u: User) => u.roles[this.data.role.shortname]
                )
            ];
        } else {
            this.usersList = this.data.application.users;
        }
    }

    getUserIcon(user: User): string {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.nationalTeacher) {
            return 'icon-auteurnational';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.externalCallManager) {
            return 'icon-Telephone';
        }
    }

    openUserDialog(user: User): void {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage(
            {
                viewUser: user.id
            },
            '*'
        );
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
