import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GraphsService } from 'src/app/services/graphs.service';
import { SiteService } from 'src/app/services/site.service';
import { Subscription } from 'rxjs';
import { PdfService } from 'src/app/services/pdf.service';

import * as FileSaver from 'file-saver';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { DialogService } from 'src/app/services/dialog.service';
import { Structure } from 'src/app/structures/structure';
import { StructuresService } from 'src/app/services/structures.service';
import { LoginService } from 'src/app/services/login.service';

import $ from 'jquery';

import {
    connexionsTypes,
    userTypes,
    activityIndicators,
    assignationsPrice,
    structuresTypes
} from './graphs-dashboard.settings';

@Component({
    selector: 'app-graphs-dashboard',
    templateUrl: './graphs-dashboard.component.html',
    styleUrls: ['./graphs-dashboard.component.scss'],
    standalone: false
})
export class GraphsDashboardComponent implements OnInit, OnDestroy {
    @Input() graphTypes: any[];
    @Input() graphTypeSelected: any;
    @Output() graphTypeSelectedChange: EventEmitter<any> = new EventEmitter();
    subscriptions: Subscription = new Subscription();
    dateStart: Date;
    dateEnd: Date;
    datesDropdownOpened = false;
    datesFilterOpened = false;
    today: Date = new Date();

    structures: Structure[];

    connexionsTypes: Array<{ title: string; key: string; selected: boolean }>;
    userTypes: Array<{ title: string; key: string; selected: boolean }>;
    activityIndicators: Array<{ title: string; key: string; selected: boolean }>;
    assignationsPrice: Array<{ title: string; key: string; selected: boolean }>;
    structuresTypes: Array<{ title: string; key: string; selected: boolean; disabled?: boolean }>;
    sites: Array<{ title: string; name: string; key: string; selected: boolean }>;
    domains: Array<{ title: string; name: string; key: string; selected: boolean }>;
    actionsCount = [
        {
            title: '1 - 10 actions effectuées',
            key: '1-10',
            selected: true,
            APIValue: {
                min: 1,
                max: 10
            }
        },
        {
            title: '10 - 100 actions effectuées',
            key: '10-100',
            selected: false,
            APIValue: {
                min: 10,
                max: 100
            }
        },
        {
            title: '100 - 1000 actions effectuées',
            key: '100-1000',
            selected: false,
            APIValue: {
                min: 100,
                max: 1000
            }
        }
    ];

    constructor(
        private graphsService: GraphsService,
        private siteService: SiteService,
        private pdfService: PdfService,
        private flashMessageService: FlashMessageService,
        private dialogService: DialogService,
        private structuresService: StructuresService,
        private loginService: LoginService
    ) {
        this.dateEnd = new Date();
        this.dateEnd.setHours(23, 59, 59, 0);
        this.dateStart = new Date();
        this.dateStart.setFullYear(this.dateStart.getFullYear() - 1);
        this.dateStart.setMonth(0);
        this.dateStart.setDate(1);
        this.dateStart.setHours(0, 1, 0, 0);
        this.connexionsTypes = connexionsTypes;
        this.userTypes = userTypes;
        this.activityIndicators = activityIndicators;
        this.assignationsPrice = assignationsPrice;
    }
    getMaxDateStart() {
        const max = new Date(this.dateEnd.toISOString());
        if (this.graphTypeSelected && this.graphTypeSelected.key !== 'activities') {
            max.setMonth(max.getMonth() - 2);
            // si au changement de graph on restaure l'écrat de dates si besoin
            if (this.dateStart > max) {
                this.dateStart = max;
            }
        }
        return max;
    }
    getMinDateEnd() {
        const min = new Date(this.dateStart.toISOString());
        if (this.graphTypeSelected && this.graphTypeSelected.key !== 'activities') {
            min.setMonth(min.getMonth() + 2);
        }
        return min;
    }

    onUpdateParameters() {
        if (!this.isStructureSelected()) {
            this.structuresTypes.find((el) => el.key === 'local').disabled = true;
        } else {
            this.structuresTypes.find((el) => el.key === 'local').disabled = false;
        }
        if (!this.graphTypeSelected) {
            return;
        }
        switch (this.graphTypeSelected.key) {
            case 'connexions':
                this.updateConnexionsGraph();
                break;
            case 'assignations':
                this.updateAssignationsGraph();
                break;
            case 'activities':
                this.updateActivitiesGraph();
                break;
            case 'active-users':
                this.updateActiveUsersGraph();
                break;
            default:
                break;
        }
    }

    onStructureChange($event: Event) {
        if (!this.isStructureSelected()) {
            this.initStructuresTypes();
        }
        this.sites = [];
        this.onUpdateParameters();
        if (this.structuresSelectedArray().length === 1) {
            const params = {
                structureid: this.structures
                    .filter((structure) => structure.selected)
                    .map((structure) => structure.id)
            };
            this.subscriptions.add(
                this.siteService.getSites(params).subscribe((data: any[]) => {
                    this.sites = this.formatSitesForDropDown(data);
                })
            );
        }
    }

    updateConnexionsGraph(): any {
        const parameters = {
            arole: this.userTypes.filter((item) => item.selected).map((item) => item.key),
            astructure: this.structuresSelectedArray(),
            asite: this.sites.filter((item) => item.selected).map((item) => item.name),
            start: this.dateStart,
            end: this.dateEnd
        };
        this.graphsService.updateConnexionData(parameters);
    }

    updateAssignationsGraph(): any {
        let chasPrice: any;
        if (!this.assignationsPrice.find((item) => item.selected)) {
            chasPrice = [0, 1];
        } else if (this.assignationsPrice.find((item) => item.selected).key === 'both') {
            chasPrice = [0, 1];
        } else {
            chasPrice = this.assignationsPrice
                .filter((item) => item.selected)
                .map((item) => item.key);
        }
        const parameters = {
            ustructure: this.structuresSelectedArray(),
            usite: this.sites.filter((item) => item.selected).map((item) => item.name),
            chasprice: chasPrice,
            start: this.dateStart,
            end: this.dateEnd,
            cdomains: this.domains.filter((item) => item.selected).map((item) => item.name) || '*'
        };
        this.graphsService.updateAssignationData(parameters);
    }

    updateActivitiesGraph(): void {
        const cdomains = this.domains.filter((item) => item.selected).map((item) => item.name);
        const parameters = {
            ustructure: undefined,
            astructure: undefined,
            cstructure:
                this.structuresTypes.find((item) => item.selected).key === 'local'
                    ? this.structuresSelectedArray()
                    : this.structuresTypes.find((item) => item.selected)
                    ? this.structuresTypes.find((item) => item.selected).key
                    : '*',
            usite: this.sites.filter((item) => item.selected).map((item) => item.name),
            start: this.dateStart,
            end: this.dateEnd,
            cdomains: cdomains.length > 0 ? cdomains : '*',
            wanted: this.activityIndicators.find((el) => el.selected).key
        };

        if (parameters.wanted === 'executions') {
            parameters.astructure = this.structuresSelectedArray();
        } else {
            parameters.ustructure = this.structuresSelectedArray();
        }

        this.graphsService.updateTopActivitiesData(parameters);
    }

    updateActiveUsersGraph(): void {
        const parameters = {
            start: this.dateStart,
            end: this.dateEnd,
            astructure: this.structuresSelectedArray(),
            site: this.sites.filter((item) => item.selected).map((item) => item.name),
            bounds: this.actionsCount.some((e) => e.selected)
                ? this.actionsCount.find((e) => e.selected).APIValue
                : '',
            arole: this.userTypes.filter((item) => item.selected).map((item) => item.key)
        };
        this.graphsService.updateActiveUsersData(parameters);
    }

    onGraphTypeChange(event) {
        this.graphTypeSelectedChange.emit(event);
        setTimeout(() => {
            this.onUpdateParameters();
        }, 0);
    }

    onConnexionTypesChange() {
        let connexionsTypesTemp: any;
        const isConnexionTypesSelected = this.connexionsTypes.find((item) => item.selected);
        if (isConnexionTypesSelected) {
            connexionsTypesTemp = isConnexionTypesSelected.key;
        } else {
            connexionsTypesTemp = 'total';
        }
        this.graphsService.setConnexionType(connexionsTypesTemp);
    }

    toggleDatesDropdown() {
        this.datesDropdownOpened = !this.datesDropdownOpened;
    }

    onClickOutsideDatesDropdown(event) {
        if (
            this.datesDropdownOpened &&
            event.target.nodeName !== 'BODY' &&
            $(event.target).parents('.mat-calendar-content').length === 0
        ) {
            this.toggleDatesDropdown();
        }
    }

    formatSitesForDropDown(sites: Array<any>) {
        return sites.map((site) => ({
            ...site,
            title: site.name,
            key: site.id
        }));
    }

    formatDomainsForDropDown(domains: Array<any>) {
        return domains
            .map((domain) => ({
                ...domain,
                title: domain.name,
                key: domain.name
            }))
            .sort((firstDomain, secondDomain) => firstDomain.name.localeCompare(secondDomain.name));
    }

    getPDFparameters(): any[] {
        switch (this.graphTypeSelected.key) {
            case 'activities':
                return [
                    {
                        name: 'Structure',
                        value: this.isStructureSelected()
                            ? this.structuresSelectedArray()
                            : 'Toutes les structures'
                    },
                    {
                        name: 'Site',
                        value: this.isSiteSelected()
                            ? this.sites
                                  .filter((el) => el.selected)
                                  .map((el) => el.title)
                                  .join(', ')
                            : 'Tous les sites'
                    },
                    {
                        name: 'Domaine',
                        value:
                            this.domains.some((el) => el.selected) &&
                            this.domains.some((el) => !el.selected)
                                ? this.domains
                                      .filter((el) => el.selected)
                                      .map((el) => el.title)
                                      .join(', ')
                                : 'Tous les domaines'
                    },
                    {
                        name: 'Eléments comptés',
                        value: this.activityIndicators.find((el) => el.selected).title
                    },
                    {
                        name: 'types de contenus',
                        value: this.structuresTypes.find((el) => el.selected).title
                    },
                    {
                        name: 'Dates',
                        value: `Du ${this.dateStart.toLocaleDateString(
                            'fr-FR'
                        )} au ${this.dateEnd.toLocaleDateString('fr-FR')}`
                    }
                ];
                break;
            case 'assignations':
                return [
                    {
                        name: 'Structure',
                        value: this.isStructureSelected()
                            ? this.structuresSelectedArray()
                            : 'Toutes les structures'
                    },
                    {
                        name: 'Site',
                        value: this.isSiteSelected()
                            ? this.sites
                                  .filter((el) => el.selected)
                                  .map((el) => el.title)
                                  .join(', ')
                            : 'Tous les sites'
                    },
                    {
                        name: 'Domaine',
                        value:
                            this.domains.some((el) => el.selected) &&
                            this.domains.some((el) => !el.selected)
                                ? this.domains
                                      .filter((el) => el.selected)
                                      .map((el) => el.title)
                                      .join(', ')
                                : 'Tous les domaines'
                    },
                    {
                        name: 'Prix',
                        value: this.assignationsPrice.find((el) => el.selected).title
                    },
                    {
                        name: 'Dates',
                        value: `Du ${this.dateStart.toLocaleDateString(
                            'fr-FR'
                        )} au ${this.dateEnd.toLocaleDateString('fr-FR')}`
                    }
                ];
                break;
            case 'connexions':
                return [
                    {
                        name: 'Structure',
                        value: this.isStructureSelected()
                            ? this.structuresSelectedArray()
                            : 'Toutes les structures'
                    },
                    {
                        name: 'Site',
                        value: this.isSiteSelected()
                            ? this.sites
                                  .filter((el) => el.selected)
                                  .map((el) => el.title)
                                  .join(', ')
                            : 'Tous les sites'
                    },
                    {
                        name: 'Type de connexions',
                        value: this.connexionsTypes.find((el) => el.selected).title
                    },
                    {
                        name: 'Roles',
                        value: this.userTypes
                            .filter((el) => el.selected)
                            .map((it) => it.title)
                            .join(', ')
                    },
                    {
                        name: 'Dates',
                        value: `Du ${this.dateStart.toLocaleDateString(
                            'fr-FR'
                        )} au ${this.dateEnd.toLocaleDateString('fr-FR')}`
                    }
                ];
                break;
            case 'active-users':
                return [
                    {
                        name: 'Structure',
                        value: this.isStructureSelected()
                            ? this.structuresSelectedArray()
                            : 'Toutes les structures'
                    },
                    {
                        name: 'Site',
                        value: this.isSiteSelected()
                            ? this.sites
                                  .filter((el) => el.selected)
                                  .map((el) => el.title)
                                  .join(', ')
                            : 'Tous les sites'
                    },
                    {
                        name: "Nombre d'actions",
                        value: this.actionsCount.find((el) => el.selected).title
                    },
                    {
                        name: 'Roles',
                        value: this.userTypes
                            .filter((el) => el.selected)
                            .map((it) => it.title)
                            .join(', ')
                    },
                    {
                        name: 'Dates',
                        value: `Du ${this.dateStart.toLocaleDateString(
                            'fr-FR'
                        )} au ${this.dateEnd.toLocaleDateString('fr-FR')}`
                    }
                ];
                break;
            default:
                break;
        }
    }

    generatePDF() {
        const title = this.graphTypeSelected.title;
        this.pdfService.generatePDF(title, this.getPDFparameters());
    }

    exportCSV() {
        switch (this.graphTypeSelected.key) {
            case 'connexions':
                this.exportConnexionsCSV();
                break;
            case 'assignations':
                this.exportAssignationsCSV();
                break;
            case 'activities':
                this.exportActivitiesCSV();
                break;
            case 'active-users':
                this.exportActiveUsersCSV();
                break;
            default:
                break;
        }
    }

    exportConnexionsCSV() {
        const parameters = {
            arole: this.userTypes.filter((item) => item.selected).map((item) => item.key),
            astructure: this.isStructureSelected() ? this.structuresSelectedArray() : '',
            // sites : si plusieurs un array, sinon une string
            asite:
                this.sites.filter((item) => item.selected).length > 1
                    ? this.sites.filter((item) => item.selected).map((item) => item.name)
                    : this.sites.filter((item) => item.selected).map((item) => item.name)[0],
            start: this.dateStart,
            end: this.dateEnd
        };

        this.flashMessageService.flash(
            'le fichier est en cours de téléchargement, veuillez patienter.'
        );
        this.subscriptions.add(
            this.graphsService.getConnexionsCSV(parameters).subscribe(
                (data) => {
                    const blob = new Blob([data], { type: 'text/plain;charset=iso-8859-1;' });
                    const options: any = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    };
                    const role =
                        this.userTypes.filter((r) => r.selected).length > 0
                            ? 'des ' +
                              this.userTypes
                                  .filter((r) => r.selected)
                                  .map((r) => r.title)
                                  .join(', ')
                            : '';
                    const site = this.isSiteSelected()
                        ? ', du site ' +
                          this.sites
                              .filter((r) => r.selected)
                              .map((r) => r.title)
                              .join(', ')
                        : '';

                    const csvName = `Nombre de connexions par mois, ${role}${site}, du ${parameters.start.toLocaleDateString(
                        'fr-FR',
                        options
                    )} au ${parameters.end.toLocaleDateString('fr-FR', options)}.csv`;
                    FileSaver.saveAs(blob, csvName);
                },
                (error) => {
                    this.dialogService.openError(
                        "Une erreur s'est produite, le fichier n'a pas pu être téléchargé"
                    );
                    this.flashMessageService.stopFlashing();
                }
            )
        );
    }

    exportAssignationsCSV() {
        let chasPrice: any;
        let priceForTheTitle;
        if (
            !this.assignationsPrice.find((item) => item.selected) ||
            this.assignationsPrice.find((item) => item.selected).key === 'both'
        ) {
            chasPrice = [0, 1];
            priceForTheTitle = '';
        } else {
            chasPrice = this.assignationsPrice
                .filter((item) => item.selected)
                .map((item) => item.key);
            priceForTheTitle = chasPrice === '1' ? 'payantes ' : 'non payantes ';
        }
        const parameters = {
            asite: this.sites.filter((item) => item.selected).map((item) => item.name),
            chasprice: chasPrice,
            start: this.dateStart,
            end: this.dateEnd,
            cdomains: this.domains.some((item) => item.selected)
                ? this.domains.filter((item) => item.selected).map((item) => item.name)
                : '*',
            ustructure: this.isStructureSelected() ? this.structuresSelectedArray() : '*'
        };
        this.flashMessageService.flash(
            'le fichier est en cours de téléchargement, veuillez patienter.'
        );
        this.subscriptions.add(
            this.graphsService.getAssignationsCSV(parameters).subscribe(
                (data) => {
                    const blob = new Blob([data], { type: 'text/plain;charset=iso-8859-1;' });
                    const options: any = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    };
                    const domain = Array.isArray(parameters.cdomains)
                        ? 'du domaine ' + parameters.cdomains.join('_')
                        : 'de tous les domaines';

                    const csvName = `Nombre d'assignations ${priceForTheTitle}par mois, ${domain}, du ${parameters.start.toLocaleDateString(
                        'fr-FR',
                        options
                    )} au ${parameters.end.toLocaleDateString('fr-FR', options)}.csv`;
                    FileSaver.saveAs(blob, csvName);
                },
                (error) => {
                    this.dialogService.openError(
                        "Une erreur s'est produite, le fichier n'a pas pu être téléchargé"
                    );
                    this.flashMessageService.stopFlashing();
                }
            )
        );
    }

    exportActivitiesCSV() {
        const cdomains = this.domains.filter((item) => item.selected).map((item) => item.name);
        const parameters = {
            ustructure: this.structuresSelectedArray(),
            usite: this.sites.filter((item) => item.selected).map((item) => item.name),
            start: this.dateStart,
            end: this.dateEnd,
            cdomains: cdomains.length > 0 ? cdomains : '*',
            cstructure:
                this.structuresTypes.find((item) => item.selected).key === 'local'
                    ? this.structuresSelectedArray()
                    : this.structuresTypes.find((item) => item.selected)
                    ? this.structuresTypes.find((item) => item.selected).key
                    : '*',
            wanted: this.activityIndicators.find((el) => el.selected).key
        };
        this.flashMessageService.flash(
            'le fichier est en cours de téléchargement, veuillez patienter.'
        );
        this.subscriptions.add(
            this.graphsService.getActivitiesCSV(parameters).subscribe(
                (data) => {
                    const blob = new Blob([data], { type: 'text/plain;charset=iso-8859-1;' });
                    const options: any = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    };
                    const domain =
                        cdomains.length > 0
                            ? 'du domaine ' + cdomains.join('_')
                            : 'de tous les domaines';
                    let structuresName = this.structuresTypes.some((item) => item.selected)
                        ? this.structuresTypes.find((item) => item.selected).key
                        : 'Nationales et local';
                    if (structuresName === '*') {
                        structuresName = 'Nationales et local';
                    }
                    const csvName = `TOP 250 des activités ${structuresName}es, ${domain}, du ${parameters.start.toLocaleDateString(
                        'fr-FR',
                        options
                    )} au ${parameters.end.toLocaleDateString('fr-FR', options)}.csv`;
                    FileSaver.saveAs(blob, csvName);
                },
                (error) => {
                    this.dialogService.openError(
                        "Une erreur s'est produite, le fichier n'a pas pu être téléchargé"
                    );
                    this.flashMessageService.stopFlashing();
                }
            )
        );
    }

    exportActiveUsersCSV() {
        const cdomains = this.domains.filter((item) => item.selected).map((item) => item.name);

        const parameters = {
            start: this.dateStart,
            end: this.dateEnd,
            astructure: this.structuresSelectedArray(),
            site: this.sites.filter((item) => item.selected).map((item) => item.name),
            bounds: this.actionsCount.find((e) => e.selected).APIValue,
            arole: this.userTypes.filter((item) => item.selected).map((item) => item.key)
        };
        this.flashMessageService.flash(
            'le fichier est en cours de téléchargement, veuillez patienter.'
        );
        this.subscriptions.add(
            this.graphsService.getActiveUsersCSV(parameters).subscribe(
                (data) => {
                    const blob = new Blob([data], { type: 'text/plain;charset=iso-8859-1;' });
                    const options: any = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    };
                    const domain =
                        cdomains.length > 0
                            ? 'du domaine ' + cdomains.join('_')
                            : 'de tous les domaines';
                    let structuresName = this.structuresTypes.some((item) => item.selected)
                        ? this.structuresTypes.find((item) => item.selected).key
                        : 'Nationales et local';
                    if (structuresName === '*') {
                        structuresName = 'Nationales et local';
                    }
                    const csvName = `Nombre d'utilisateurs actifs, du ${parameters.start.toLocaleDateString(
                        'fr-FR',
                        options
                    )} au ${parameters.end.toLocaleDateString('fr-FR', options)}.csv`;
                    FileSaver.saveAs(blob, csvName);
                },
                (error) => {
                    this.dialogService.openError(
                        "Une erreur s'est produite, le fichier n'a pas pu être téléchargé"
                    );
                    this.flashMessageService.stopFlashing();
                }
            )
        );
    }

    ngOnInit() {
        this.initStructuresTypes();
        this.subscriptions.add(
            this.siteService.getSites({}).subscribe((data: any) => {
                this.sites = this.formatSitesForDropDown(data);
            })
        );
        this.subscriptions.add(
            this.siteService.getDomains().subscribe((data: any) => {
                this.domains = this.formatDomainsForDropDown(data);
            })
        );
        this.subscriptions.add(
            this.structuresService.getStructures().subscribe((data: Structure[]) => {
                this.structures = data
                    .map((structure: Structure) => ({
                        ...structure,
                        key: structure.id,
                        title: structure.name,
                        selected: this.loginService.getUser().structureid === structure.id
                    }))
                    .sort((a, b) => a.title.localeCompare(b.title));
                if (!this.loginService.getUser().roles.nationalAdmin) {
                    this.onStructureChange(undefined);
                }
            })
        );
    }

    showStructuresDropDown() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isStructureSelected(): boolean {
        return this.structures && this.structures.some((s) => s.selected);
    }

    isSiteSelected(): boolean {
        return this.sites && this.sites.some((s) => s.selected);
    }

    structuresSelectedArray(): string[] {
        if (!this.isStructureSelected()) {
            return [];
        }
        if (!this.structures.some((s) => !s.selected)) {
            return [];
        }
        return this.structures.filter((s) => s.selected).map((s) => s.name);
    }

    initStructuresTypes(): void {
        this.structuresTypes = structuresTypes;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
