import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UsersService } from 'src/app/services/users.service';
import { GroupsService } from 'src/app/services/groups.service';
import { LoginService } from 'src/app/services/login.service';
import { SiteService } from 'src/app/services/site.service';

import { FlashMessageService } from 'src/app/services/flash-message.service';

import { Group } from '../../structures/group';
import { Role } from '../../structures/role';

import { Site } from '../../structures/site';

@Component({
    selector: 'app-create-role-group',
    templateUrl: './create-role-group.component.html',
    styleUrls: ['./create-role-group.component.scss'],
    standalone: false
})
export class CreateRoleGroupComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public roleGroup: Group,
        public dialogRef: MatDialogRef<CreateRoleGroupComponent>,
        private usersService: UsersService,
        private groupsService: GroupsService,
        private loginService: LoginService,
        private siteService: SiteService,
        private flashMessageService: FlashMessageService
    ) {}

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    roles: Array<Role>;
    sites: Array<Site>;

    siteSearch: string;

    ngOnInit() {
        this.siteSearch = '';
        if (!this.roleGroup.name) {
            this.roleGroup.name = '';
            this.roleGroup.roles = [];
            this.roleGroup.sites = [];
        }
        this.usersService.getRoles().subscribe((roles: Array<any>) => {
            this.roles = roles
                .map((role) => {
                    if (role.shortname === 'learner') {
                        role = [
                            {
                                ...role,
                                name: 'Apprenant (FI)',
                                data: { trainingtype: ['FI'] }
                            },
                            {
                                ...role,
                                name: 'Apprenant (FC)',
                                data: { trainingtype: ['FC'] }
                            }
                        ];
                    }
                    console.log(role);
                    return role;
                })
                .flat();

            this.initRolesSelected();
        });
        const params = { structureid: this.loginService.getUser().structureid };
        this.siteService.getSites(params).subscribe((sites: Array<Site>) => {
            this.sites = sites;
            this.initSitesSelected();
        });
    }

    initRolesSelected() {
        this.roles.map((role) => {
            if (
                this.roleGroup.roles.findIndex((role2) => {
                    if (role2.role_shortname === 'learner' && role.shortname === 'learner') {
                        if (role2.data.trainingtype.indexOf(role.data.trainingtype[0]) > -1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return role2.role_shortname === role.shortname;
                    }
                }) > -1
            ) {
                role.selected = true;
            }
        });
    }

    initSitesSelected() {
        this.sites.map((site) => {
            if (this.roleGroup.sites.findIndex((site2) => site2.site_name === site.name) > -1) {
                site.selected = true;
            }
        });
    }

    getTitle() {
        return !this.roleGroup.id ? 'Créer un groupe' : 'Modifier le groupe';
    }

    isAllRolesSelected() {
        if (this.roles) {
            return this.roles.filter((role) => !role.selected).length === 0;
        }
    }

    getRoleSelectedCount() {
        if (this.roles) {
            return this.roles.filter((role) => role.selected).length;
        }
    }

    selectAllRoles() {
        this.roles.map((role) => (role.selected = true));
    }

    unselectAllRoles() {
        this.roles.map((role) => (role.selected = false));
    }

    toggleRoleSelected(role) {
        role.selected = !role.selected;
    }

    getFilteredSites() {
        if (this.sites) {
            return this.sites.filter((site) =>
                site.name.toLowerCase().includes(this.siteSearch.toLowerCase())
            );
        }
    }

    getSiteSelectedCount() {
        if (this.sites) {
            return this.sites.filter((site) => site.selected).length;
        }
    }

    isAllSitesSelected() {
        if (this.sites) {
            return this.sites.filter((site) => !site.selected).length === 0;
        }
    }

    selectAllSites() {
        this.sites.map((site) => (site.selected = true));
    }

    unselectAllSites() {
        this.sites.map((site) => (site.selected = false));
    }

    toggleSiteSelected(site) {
        site.selected = !site.selected;
    }

    createRoleGroup() {
        if (!this.roleGroup.id) {
            this.groupsService
                .createRoleGroup(this.getFormattedRoleGroup())
                .subscribe((data: any) => {
                    this.flashMessageService.flash(`Le groupe ${this.roleGroup.name} a été créé`);
                    data.roles = this.getFormattedRoleGroup().roles;
                    data.sites = this.getFormattedRoleGroup().sites;
                    this.dialogRef.close(data);
                });
        } else {
            this.groupsService
                .updateRoleGroup(this.getFormattedRoleGroup())
                .subscribe((data: any) => {
                    this.flashMessageService.flash(
                        `Le groupe ${this.roleGroup.name} a été mis à jour`
                    );
                    data.roles = this.getFormattedRoleGroup().roles;
                    data.sites = this.getFormattedRoleGroup().sites;
                    this.dialogRef.close(data);
                });
        }
    }

    getFormattedRoleGroup() {
        const learnerRoles = this.roles.filter((role) => role.shortname === 'learner');
        const learnerRole = this.roles.find((role) => role.shortname === 'learner');
        learnerRole.data.trainingtype = [];
        if (learnerRoles[0].selected) {
            learnerRole.data.trainingtype.push('FI');
        }
        if (learnerRoles[1].selected) {
            learnerRole.data.trainingtype.push('FC');
        }
        const roleGroup = {
            ...this.roleGroup,
            structureid: this.loginService.getUser().structureid,
            roles: this.roles.filter((role) => role.selected && role.shortname !== 'learner'),
            sites: this.sites.filter((site) => site.selected)
        };
        if (learnerRole.data.trainingtype.length) {
            roleGroup.roles.push(learnerRole);
        }
        return roleGroup;
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }
}
