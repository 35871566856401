import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { StructuresService } from 'src/app/services/structures.service';
import { ActivityReportService } from '../../../services/activity-report.service';
import { GroupsService } from '../../../services/groups.service';
import { UsersService } from '../../../services/users.service';
import { FlashMessageService } from '../../../services/flash-message.service';
import { LoginService } from '../../../services/login.service';
import { LoadingService } from '../../../services/loading.service';
import { ConfigService } from '../../../services/config.service';

import { Group } from '../../../structures/group';
import { User } from '../../../structures/user';
import { Structure } from '../../../structures/structure';

import * as FileSaver from 'file-saver';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
    selector: 'app-activity-report',
    templateUrl: './activity-report.component.html',
    styleUrls: ['./activity-report.component.scss'],
    standalone: false
})
export class ActivityReportComponent implements OnInit {
    constructor(
        private activityReportService: ActivityReportService,
        private flashMessageService: FlashMessageService,
        private groupsService: GroupsService,
        private structuresService: StructuresService,
        private usersService: UsersService,
        private loginService: LoginService,
        private loadingService: LoadingService,
        private dialogService: DialogService,
        private configService: ConfigService
    ) {}

    subscriptions: Subscription = new Subscription();

    today: Date;
    yesterday: Date;
    minDate: Date;

    structures: Array<Structure>;
    users: Array<User> = [];
    usersCurrentPage = 0;
    groups: Array<Group> = [];
    groupsCurrentPage: 0;

    searchTerm = '';

    printCapabilities = true;
    printCapabilitiesDates: {
        start: Date;
        end: Date;
    };
    printLearnerActivity = true;
    printLearnerActivityDates: {
        start: Date;
        end: Date;
    };

    elementType = [
        {
            title: 'Apprenants',
            key: 'learners',
            selected: true
        },
        {
            title: 'Groupes',
            key: 'groups',
            selected: false
        }
    ];

    ngOnInit() {
        this.today = new Date();
        this.today.setHours(23, 59, 59, 0);
        this.yesterday = new Date(this.today.getTime() - 24 * 60 * 60 * 1000);
        this.minDate = new Date('2020-06-01T00:00:00.000Z');
        this.printCapabilitiesDates = {
            start: this.minDate,
            end: this.yesterday
        };
        this.printLearnerActivityDates = {
            start: this.minDate,
            end: this.yesterday
        };
        this.subscriptions.add(
            this.structuresService.getStructures().subscribe((data: Structure[]) => {
                this.structures = data.map((structure: Structure) => ({
                    ...structure,
                    key: structure.id,
                    title: structure.name,
                    selected: this.loginService.getUser().structureid === structure.id
                }));
                if (!this.loginService.getUser().roles.nationalAdmin) {
                    this.onStructureChange(undefined);
                }
            })
        );
    }

    isNationalAdmin() {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }

    onStructureChange($event: Event) {
        if (this.elementTypeSelected() === 'learners') {
            this.groups = [];
            this.usersCurrentPage = 0;
            const params = {
                structureid: this.structures
                    .filter((structure) => structure.selected)
                    .map((structure) => structure.id),
                search: this.searchTerm,
                limit: 30,
                offset: this.usersCurrentPage
            };
            this.loadingService.startLoading('activityReportMainView', 'getUsers');
            this.subscriptions.add(
                this.usersService.getUsers(params).subscribe((users: User[]) => {
                    this.loadingService.stopLoading('activityReportMainView', 'getUsers');
                    this.users = [
                        // users sélectionnés en haut de la liste :
                        ...this.usersSelected(),
                        // résultats, dont on a soustrait les évelntuels users déjà seletionnés :
                        ...users
                            .filter(
                                (user) =>
                                    !this.usersSelected().some(
                                        (selectedUser) => selectedUser.id === user.id
                                    )
                            )
                            .map((user) => ({
                                ...user,
                                title: user.username,
                                key: user.id,
                                selected: false
                            }))
                    ];
                })
            );
        } else if (this.elementTypeSelected() === 'groups') {
            this.users = [];
            this.groupsCurrentPage = 0;
            const params = {
                structureid: this.structures
                    .filter((structure) => structure.selected)
                    .map((structure) => structure.id),
                limit: 30,
                offset: this.groupsCurrentPage,
                search: this.searchTerm,
                type: 'learner'
            };
            this.loadingService.startLoading('activityReportMainView', 'getGroups');
            this.groupsService.getGroups(params).subscribe((groupsLearner: Group[]) => {
                params.type = 'teacher';
                this.groupsService.getGroups(params).subscribe((groupsTeacher: Group[]) => {
                    this.loadingService.stopLoading('activityReportMainView', 'getGroups');
                    this.groups = groupsLearner
                        .map((group: Group) => ({
                            ...group,
                            title: group.name,
                            key: group.id,
                            selected: false
                        }))
                        .concat(
                            groupsTeacher.map((group: Group) => ({
                                ...group,
                                title: group.name,
                                key: group.id,
                                selected: false
                            }))
                        );
                });
            });
        }
    }

    nextPageLearners() {
        this.usersCurrentPage++;
        const params = {
            structureid: this.structures
                .filter((structure) => structure.selected)
                .map((structure) => structure.id),
            limit: 30,
            offset: this.usersCurrentPage * 30,
            search: this.searchTerm
        };
        this.loadingService.startLoading('activityReportNextPage', 'getUsers');
        this.subscriptions.add(
            this.usersService.getUsers(params).subscribe((users: User[]) => {
                this.loadingService.stopLoading('activityReportNextPage', 'getUsers');
                this.users = [
                    ...this.users,
                    ...users.map((user: User) => ({
                        ...user,
                        title: user.username,
                        key: user.id,
                        selected: false
                    }))
                ];
            })
        );
    }

    nextPageGroups() {
        this.groupsCurrentPage++;
        const params = {
            structureid: this.structures
                .filter((structure) => structure.selected)
                .map((structure) => structure.id),
            limit: 30,
            offset: this.groupsCurrentPage * 30,
            search: this.searchTerm,
            type: 'learner'
        };
        this.loadingService.startLoading('activityReportNextPage', 'getGroups');
        this.groupsService.getGroups(params).subscribe((groupsLearner: Group[]) => {
            params.type = 'teacher';
            this.groupsService.getGroups(params).subscribe((groupsTeacher: Group[]) => {
                this.loadingService.stopLoading('activityReportNextPage', 'getGroups');
                this.groups = [
                    ...this.groups,
                    ...groupsLearner
                        .map((group: Group) => ({
                            ...group,
                            title: group.name,
                            key: group.id,
                            selected: false
                        }))
                        .concat(
                            groupsTeacher.map((group: Group) => ({
                                ...group,
                                title: group.name,
                                key: group.id,
                                selected: false
                            }))
                        )
                ];
            });
        });
    }

    getUserIcon(element: any): string {
        if (!element.roles) {
            return 'icon-groupe';
        }
        if (element.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (element.roles.learner) {
            return 'icon-apprenant';
        } else if (element.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (element.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (element.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (element.roles.nationalTeacher) {
            return 'icon-auteurnational';
        } else if (element.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (element.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (element.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (element.roles.nationalAdmin) {
            return 'icon-adminnational';
        } else if (element.roles.externalCallManager) {
            return 'icon-Telephone';
        }
    }

    getYpareoID(user: User): string {
        if (
            user.externalID &&
            (user.externalID.charAt(0).toLowerCase() === 'a' ||
                user.externalID.charAt(0).toLowerCase() === 'f')
        ) {
            return user.externalID;
        }
        return undefined;
    }

    selectUser(user: User): void {
        user.selected = !user.selected;
    }

    selectGroup(group: Group): void {
        if (group.selected) {
            group.selected = false;
        } else {
            this.groups.map((g) => (g.selected = false));
            group.selected = true;
        }
    }

    usersSelected(): Array<User> {
        return this.users.filter((u) => u.selected);
    }

    groupSelected(): Group {
        return this.groups.filter((u) => u.selected)[0];
    }

    isEnabledUser(user: User) {
        return user.enabled;
    }

    isValidDate(user: User) {
        let valid = !!(user.startaccount || user.endaccount);
        if (valid && user.startaccount) {
            valid = new Date(user.startaccount) < new Date();
        }
        if (valid && user.endaccount) {
            valid = new Date() < new Date(user.endaccount);
        }
        return valid;
    }

    isInvalidDate(user: User) {
        let invalid = false;
        if (!user.startaccount && !user.endaccount) {
            return false;
        }
        if (!invalid && user.startaccount) {
            invalid = !(new Date(user.startaccount) < new Date());
        }
        if (!invalid && user.endaccount) {
            invalid = !(new Date() < new Date(user.endaccount));
        }
        return invalid;
    }

    getDateTooltip(user: User) {
        if (user.startaccount && !user.endaccount) {
            return (
                'Utilisateur actif à partir du ' +
                new Date(user.startaccount).toLocaleDateString('fr-FR')
            );
        } else if (!user.startaccount && user.endaccount) {
            return (
                "Utilisateur actif jusqu'au " +
                new Date(user.endaccount).toLocaleDateString('fr-FR')
            );
        } else if (user.startaccount && user.endaccount) {
            return (
                'Utilisateur actif du ' +
                new Date(user.startaccount).toLocaleDateString('fr-FR') +
                ' au ' +
                new Date(user.endaccount).toLocaleDateString('fr-FR')
            );
        }
    }

    /**
     * retourne la key du type d'élément (learners/groups) sélectionné
     */
    elementTypeSelected(): string {
        if (this.elementType.some((item) => item.selected)) {
            return this.elementType.find((item) => item.selected).key;
        }
    }

    getCounterLabel(): string {
        switch (this.elementTypeSelected()) {
            case 'learners':
                if (this.users !== undefined && this.users.length > 0) {
                    return `${
                        this.users.filter((el) => el.selected).length
                    } utilisateurs sélectionnés`;
                }
                return '0 utilisateurs sélectionnés';
            case 'groups':
                if (this.groups !== undefined && this.groups.length > 0) {
                    return `${this.groups.filter((el) => el.selected).length} groupes sélectionnés`;
                }
                return '0 groupes sélectionnés';
            default:
                return '';
        }
    }

    getCapabilitiesIcon(): string {
        return this.printCapabilities ? 'icon-selected' : 'icon-select';
    }
    getActivityIcon(): string {
        return this.printLearnerActivity ? 'icon-selected' : 'icon-select';
    }

    togglePrintItem(itemName: string): void {
        this[itemName] = !this[itemName];
    }

    isDownloadDisabled() {
        return (
            (this.usersSelected().length === 0 && !this.groupSelected()) ||
            (!this.printCapabilities && !this.printLearnerActivity) ||
            (this.printCapabilities &&
                (!this.printCapabilitiesDates.start || !this.printCapabilitiesDates.end)) ||
            (this.printLearnerActivity &&
                (!this.printLearnerActivityDates.start || !this.printLearnerActivityDates.end))
        );
    }

    getDownloadTooltip() {
        if (this.isDownloadDisabled()) {
            return 'Les paramètres sélectionnés sont invalides';
        } else {
            return 'Télécharger le rapport des utilisateurs sélectionnés';
        }
    }

    getElementsSelected() {
        if (this.elementTypeSelected() === 'learners') {
            return this.usersSelected();
        } else {
            return this.groupSelected();
        }
    }

    downloadReport() {
        if (this.isDownloadDisabled()) {
            return;
        }

        const overviewdates = this.printCapabilities ? this.printCapabilitiesDates : false;
        const activitydates = this.printLearnerActivity ? this.printLearnerActivityDates : false;

        this.activityReportService
            .checkDownload(this.getElementsSelected())
            .subscribe((willDownloadPDF) => {
                if (!willDownloadPDF) {
                    this.dialogService
                        .openConfirmationDialog(
                            "Téléchargement du rapport d'activité",
                            'Vous avez demandé à télécharger un grand nombre de rapports apprenants. Pour éviter tout ralentissement, un email vous sera envoyé lorsque la génération sera terminée.',
                            {
                                yes: 'OK',
                                no: 'Annuler'
                            }
                        )
                        .subscribe((data: boolean) => {
                            if (data) {
                                this.subscriptions.add(
                                    this.activityReportService
                                        .getPDFReport(
                                            this.getElementsSelected(),
                                            overviewdates,
                                            activitydates
                                        )
                                        .subscribe(
                                            () => {
                                                this.flashMessageService.flash(
                                                    'Un email vous sera envoyé lorsque la génération du fichier sera terminé'
                                                );
                                            },
                                            (error: HttpErrorResponse) => {
                                                error.error.text().then((event) => {
                                                    this.dialogService.openError(
                                                        JSON.parse(event).userMessage
                                                    );
                                                });
                                            }
                                        )
                                );
                            }
                        });
                } else {
                    this.loadingService.startLoading('downloadActivityReportMainView', 'download');
                    this.flashMessageService.flash(
                        'Votre téléchargement est en cours, veuillez patienter...'
                    );
                    this.subscriptions.add(
                        this.activityReportService
                            .getPDFReport(this.getElementsSelected(), overviewdates, activitydates)
                            .subscribe(
                                (zipFile) => {
                                    const blob = new Blob([zipFile.body], {
                                        type: 'application/octet-stream'
                                    });
                                    FileSaver.saveAs(
                                        blob,
                                        zipFile.headers
                                            .get('Content-Disposition')
                                            .match(/filename="(.+)"/)[1]
                                    );
                                    this.loadingService.stopLoading(
                                        'downloadActivityReportMainView',
                                        'download'
                                    );
                                    this.flashMessageService.flash(
                                        'Le téléchargement du rapport est terminé'
                                    );
                                },
                                (error: HttpErrorResponse) => {
                                    error.error.text().then((event) => {
                                        this.loadingService.stopLoading(
                                            'downloadActivityReportMainView',
                                            'download'
                                        );
                                        this.dialogService.openError(JSON.parse(event).userMessage);
                                    });
                                }
                            )
                    );
                }
            });
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }
}
