import { Component, Inject, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { StructuresService } from '../../services/structures.service';
import { FlashMessageService } from '../../services/flash-message.service';

import { Structure } from '../../structures/structure';

@Component({
    selector: 'app-create-structure',
    templateUrl: './create-structure.component.html',
    styleUrls: ['./create-structure.component.scss'],
    standalone: false
})
export class CreateStructureComponent implements OnInit, OnDestroy {
    constructor(
        @Inject(MAT_DIALOG_DATA) public structure: Structure,
        public dialogRef: MatDialogRef<CreateStructureComponent>,
        private flashMessageService: FlashMessageService,
        private structureService: StructuresService
    ) {}

    @Output()
    openErrorDialog: EventEmitter<string> = new EventEmitter();

    subscriptions: Subscription = new Subscription();

    ngOnInit() {
        if (!this.structure) {
            this.structure = {
                name: '',
                shortname: ''
            };
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getTitle() {
        if (!this.structure.id) {
            return 'Créer la structure';
        } else {
            return 'Modifier la structure';
        }
    }

    createStructure() {
        if (this.structure.id) {
            if (this.structure.name) {
                this.subscriptions.add(
                    this.structureService.updateStructure(this.structure).subscribe((data: any) => {
                        this.flashMessageService.flash(
                            `La structure ${this.structure.name} a été modifiée`
                        );
                        this.closeDialog();
                    })
                );
            }
        } else {
            if (this.structure.name) {
                this.subscriptions.add(
                    this.structureService.createStructure(this.structure).subscribe((data: any) => {
                        this.flashMessageService.flash(
                            `La structure ${this.structure.name} a été crée`
                        );
                        this.closeDialog();
                    })
                );
            }
        }
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }
}
