<nav class="menu">
    <div routerLink="rights" routerLinkActive="active" *ngIf="show('rights')">
        <span class="icon icon-AdminPersonnaliser"></span>
        <span>Droits par rôle</span>
    </div>
    <div routerLink="users" routerLinkActive="active" *ngIf="show('users')">
        <span class="icon icon-AdminUsers"></span>
        <span>Utilisateurs</span>
    </div>

    <div routerLink="groups" routerLinkActive="active" *ngIf="show('groups')">
        <span class="icon icon-groupe"></span>
        <span>Groupes</span>
    </div>

    <div routerLink="roleGroups" routerLinkActive="active" *ngIf="show('roleGroups')">
        <span class="icon icon-porte-voix"></span>
        <span>Groupes de rôles</span>
    </div>

    <div routerLink="contacts" routerLinkActive="active" *ngIf="show('contacts')">
        <span class="icon icon-apprenant"></span>
        <span>Contacts</span>
    </div>

    <div routerLink="structures" routerLinkActive="active" *ngIf="show('structures')">
        <span class="icon icon-adminlocal"></span>
        <span>Structures et sites</span>
    </div>

    <div routerLink="sites" routerLinkActive="active" *ngIf="show('sites')">
        <span class="icon icon-AdminSite"></span>
        <span>Sites</span>
    </div>

    <div routerLink="history" routerLinkActive="active" *ngIf="show('history')">
        <span class="icon icon-AdminStatistiques"></span>
        <span>Historique</span>
    </div>

    <div routerLink="unusedElements" routerLinkActive="active" *ngIf="show('unusedElements')">
        <span class="icon icon-Balai"></span>
        <span>Supprimer les éléments inutilisés</span>
    </div>

    <div
        routerLink="nationalAdministration"
        routerLinkActive="active"
        *ngIf="show('nationalAdministration')"
    >
        <span class="icon icon-adminnational"></span>
        <span>Administration national</span>
    </div>

    <div routerLink="dashboard" routerLinkActive="active" *ngIf="show('dashboard')">
        <span class="icon icon-reporting"></span>
        <span>Tableau de bord</span>
    </div>

    <div
        routerLink="nationalStatistics"
        routerLinkActive="active"
        *ngIf="show('nationalStatistics')"
    >
        <span class="icon icon-reporting"></span>
        <span>Tableau de bord national</span>
    </div>

    <div routerLink="news" routerLinkActive="active" *ngIf="show('news')">
        <span class="icon icon-AdminNews"></span>
        <span>News</span>
    </div>

    <div routerLink="activityReport" routerLinkActive="active" *ngIf="show('activityReport')">
        <span class="icon icon-reporting"></span>
        <span>Rapport d'activité apprenant</span>
    </div>
    <div routerLink="importYpareo" routerLinkActive="active" *ngIf="show('importYpareo')">
        <span class="icon icon-UserYPareo"></span>
        <span>Import d'utilisateurs Ypareo</span>
    </div>
</nav>

<div class="content">
    <router-outlet></router-outlet>
</div>
