import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DialogService } from 'src/app/services/dialog.service';
import { LoadingService } from 'src/app/services/loading.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { UsersService } from 'src/app/services/users.service';

import { User } from 'src/app/structures/user';
import { Role } from 'src/app/structures/role';

@Component({
    selector: 'app-national-administration',
    templateUrl: './national-administration.component.html',
    styleUrls: ['./national-administration.component.scss'],
    standalone: false
})
export class NationalAdministrationComponent implements OnInit, OnDestroy {
    constructor(
        private dialogService: DialogService,
        private loadingService: LoadingService,
        private flashMessageService: FlashMessageService,
        private usersService: UsersService
    ) {}

    private subscription$: Subscription = new Subscription();
    administratorsList: User[];
    roles: Array<Role>;

    ngOnInit() {
        this.loadingService.startLoading('nationalAdministration', 'getUsers');
        this.subscription$.add(
            this.usersService
                .getUsers({
                    structureid: [''],
                    limit: 200
                })
                .subscribe((data: User[]) => {
                    this.loadingService.stopLoading('nationalAdministration', 'getUsers');
                    this.administratorsList = data;
                })
        );

        this.usersService.getRolesAndTags().subscribe((roles: Array<Role>) => {
            this.roles = roles;
        });
    }

    ngOnDestroy() {
        this.subscription$.unsubscribe();
    }

    createUser($event) {
        $event.stopImmediatePropagation();
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage(
            {
                viewUser: -1
            },
            '*'
        );
    }

    openUserDialog(user: User): void {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage(
            {
                viewUser: user.id
            },
            '*'
        );
    }

    enableUser(user: User) {
        this.dialogService
            .openConfirmationDialog(
                `Activer l\'utilisateur`,
                `Êtes-vous sûr de vouloir activer l'utilisateur ${user.lastname.toUpperCase()} ${
                    user.firstname
                }?`
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.usersService.enableUser(user).subscribe(() => {
                        user.enabled = true;
                        this.flashMessageService.flash(
                            `<strong>${user.lastname.toUpperCase()} ${
                                user.firstname
                            }</strong> a été activé.`
                        );
                    });
                }
            });
    }

    disableUser(user: User) {
        this.dialogService
            .openConfirmationDialog(
                `Désactiver l\'utilisateur`,
                `Êtes-vous sûr de vouloir désactiver l'utilisateur ${user.lastname.toUpperCase()} ${
                    user.firstname
                }?`
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.usersService.disableUser(user).subscribe(() => {
                        user.enabled = false;
                        this.flashMessageService.flash(
                            `<strong>${user.lastname.toUpperCase()} ${
                                user.firstname
                            }</strong> a été désactivé.`
                        );
                    });
                }
            });
    }

    hasIncompleteEvents(user: User): boolean {
        return !user.events.CGU || !user.events.emailverified || !user.events.passwordverified;
    }

    getRoleLabel(shortname: string) {
        return this.roles.filter((role: Role) => role.shortname === shortname)[0].name;
    }

    isLoading() {
        return this.loadingService.isLoading('nationalAdministration');
    }
}
