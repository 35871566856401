import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { FlashMessageService } from '../../../services/flash-message.service';
import { LoginService } from '../../../services/login.service';
import { LoadingService } from '../../../services/loading.service';
import { ContactService } from '../../../services/contact.service';
import { StructuresService } from '../../../services/structures.service';
import { DialogService } from '../../../services/dialog.service';

import { Structure } from '../../../structures/structure';
import { Contact } from '../../../structures/contact';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss'],
    standalone: false
})
export class ContactsComponent implements OnInit {
    constructor(
        private flashMessageService: FlashMessageService,
        private loginService: LoginService,
        private loadingService: LoadingService,
        private structuresService: StructuresService,
        private dialogService: DialogService,
        private contactService: ContactService
    ) {}

    subscriptions: Subscription = new Subscription();

    contacts: Array<Contact> = [];

    structures: Array<Structure>;

    ngOnInit() {
        this.contacts = [];
        this.subscriptions.add(
            this.structuresService.getStructures().subscribe((data: Structure[]) => {
                data.push({
                    name: 'Administration nationale',
                    title: 'Administration nationale',
                    id: 0,
                    key: 0
                });
                this.structures = data.map((structure: Structure) => ({
                    ...structure,
                    key: structure.id,
                    title: structure.name,
                    selected: this.loginService.getUser().structureid === structure.id
                }));
                if (!this.loginService.getUser().roles.nationalAdmin) {
                    this.onFiltersChange();
                }
            })
        );

        this.contactService.contactCreated.subscribe((contact: Contact) => {
            if (this.structureSelected() === contact.structureid) {
                this.contacts.push(contact);
            }
        });
        this.contactService.contactUpdated.subscribe((contact: Contact) => {
            if (this.structureSelected() === contact.structureid) {
                this.contacts.splice(
                    this.contacts.findIndex((contactTmp) => contactTmp.id === contact.id),
                    1,
                    contact
                );
            }
        });
        this.contactService.contactDeleted.subscribe((contactId: number) => {
            this.contacts.splice(
                this.contacts.findIndex((contact) => contact.id === contactId),
                1
            );
        });
    }

    onFiltersChange(): void {
        if (this.structureSelected() === undefined) {
            return;
        }
        this.contacts = [];
        this.getContacts();
    }

    getContacts(): void {
        const params: any = {
            structureid: this.structureSelected()
        };
        this.subscriptions.add(
            this.contactService.getContacts(params).subscribe((data: Array<Contact>) => {
                this.contacts = data;
                this.loadingService.stopLoading('contactsMainView', 'getContacts');
            })
        );
    }

    isNationalAdmin(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }

    structureSelected(): number | undefined {
        if (this.structures && this.structures.find((i) => i.selected)) {
            return this.structures.find((item) => item.selected).key;
        }
        return undefined;
    }

    openCreateContact(): void {
        this.dialogService.openCreateContact().subscribe((data: boolean) => {
            if (data) {
                this.flashMessageService.flash('Le contact a été créé');
            }
        });
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }
}
