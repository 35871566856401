import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { FlashMessageService } from '../../../services/flash-message.service';
import { LoginService } from '../../../services/login.service';
import { LoadingService } from '../../../services/loading.service';
import { SiteService } from '../../../services/site.service';
import { StructuresService } from '../../../services/structures.service';
import { UsersService } from '../../../services/users.service';

import { Role } from '../../../structures/role';
import { Site } from '../../../structures/site';
import { Structure } from '../../../structures/structure';
import { User } from '../../../structures/user';

import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    standalone: false
})
export class HistoryComponent implements OnInit, OnDestroy {
    constructor(
        private loginService: LoginService,
        private loadingService: LoadingService,
        private structuresService: StructuresService,
        private sitesService: SiteService,
        private usersService: UsersService,
        private flashMessageService: FlashMessageService
    ) {}

    $subscriptions: Subscription = new Subscription();
    structures: Array<Structure>;
    sites: Array<Site>;
    corporations: Array<Site>;
    roles: Array<Role>;
    additionalRoles: Array<Role>;

    limit = 30;
    offset = 0;
    searchTerm = '';
    startDate: Date;
    endDate: Date;
    today: Date;
    showDisabledUsers = false;

    users: Array<User> = [];
    usersCount: number;
    disabledUsersCount: number;

    ngOnInit(): void {
        this.today = new Date();
        this.startDate = new Date();
        this.endDate = new Date();
        this.startDate.setMonth(this.startDate.getMonth() - 1, this.startDate.getDate() - 1);
        this.startDate.setUTCHours(22, 0, 0, 0);
        this.endDate.setUTCHours(21, 59, 59, 0);

        this.$subscriptions.add(
            this.structuresService.structures$.subscribe((data: Array<Structure>) => {
                this.structures = data.map((structure) => ({
                    ...structure,
                    selected: this.loginService.getUser().structureid === structure.id
                }));
                if (this.structureSelected()) {
                    this.getSites();
                    this.getCorporations();
                    this.nextPageUsers();
                }
            })
        );
        this.usersService.getRolesAndTags().subscribe((data: any) => {
            this.roles = data;
        });

        this.usersService.getAdditionalRoles().subscribe((data: any) => {
            this.additionalRoles = data;
        });
    }

    ngOnDestroy(): void {
        this.$subscriptions.unsubscribe();
    }

    isNationalAdmin(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }

    isLocalAdmin(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.localAdmin;
        }
    }

    getFullStructuresSelected(): Structure[] | undefined {
        if (this.structures && this.structures.find((i) => i.selected)) {
            return this.structures.filter((item) => item.selected);
        }
        return undefined;
    }

    getRolesItems() {
        if (this.roles) {
            if (
                this.getFullStructuresSelected().find((structure) => structure.shortname === 'N4S')
            ) {
                return this.roles.concat(this.additionalRoles);
            } else {
                return this.roles
                    .filter((role) => role.shortname !== 'nationalTeacher')
                    .concat(this.additionalRoles);
            }
        } else {
            return [];
        }
    }

    userTypeSelected(): Role {
        if (this.roles && this.additionalRoles) {
            return this.roles.concat(this.additionalRoles).find((el) => el.selected);
        }
    }

    getUserRoles(user): Role[] {
        if (this.roles) {
            return this.roles.filter((role: Role) => user.roles[role.shortname]);
        }
    }

    hasIncompleteEvents(user: User): boolean {
        return !user.events.CGU || !user.events.emailverified || !user.events.passwordverified;
    }

    onStructureChange() {
        if (this.structureSelected()) {
            this.getSites();
            this.getCorporations();
            this.onFiltersChange();
        }
    }

    onFiltersChange() {
        this.offset = 0;
        this.users = [];
        this.nextPageUsers();
    }

    resetFilters() {
        this.users = [];
        this.offset = 0;
        this.roles.forEach((item) => {
            item.selected = false;
        });
        this.additionalRoles.forEach((item) => {
            item.selected = false;
        });
        this.today = new Date();
        this.startDate = new Date();
        this.endDate = new Date();
        this.startDate.setMonth(this.startDate.getMonth() - 1, this.startDate.getDate() - 1);
        this.startDate.setUTCHours(22, 0, 0, 0);
        this.endDate.setUTCHours(21, 59, 59, 0);

        if (this.isNationalAdmin()) {
            this.sites = [];
            this.corporations = [];
            this.structures.forEach((st) => {
                st.selected = false;
            });
        } else {
            this.sites.forEach((item) => {
                item.selected = false;
            });
            this.corporations.forEach((item) => {
                item.selected = false;
            });
            this.nextPageUsers();
        }
    }

    resetSearch(): void {
        this.searchTerm = '';
        this.onFiltersChange();
    }

    structureSelected(): number | undefined {
        if (this.structures && this.structures.some((i) => i.selected)) {
            return this.structures.find((item) => item.selected).key;
        }
        return undefined;
    }

    getSites(): void {
        if (this.structureSelected()) {
            this.loadingService.startLoading('historyMainView', 'getSites');
            this.$subscriptions.add(
                this.sitesService
                    .getSites({
                        structureid: this.structureSelected(),
                        isCorporation: false
                    })
                    .subscribe((sitesReceived: Site[]) => {
                        this.loadingService.stopLoading('historyMainView', 'getSites');
                        this.sites = sitesReceived;
                    })
            );
        }
    }

    siteSelected(): Site | undefined {
        if (this.sites && this.sites.some((s) => s.selected)) {
            return this.sites.find((s) => s.selected);
        }
        return undefined;
    }

    getCorporations(): void {
        if (this.structureSelected()) {
            this.loadingService.startLoading('historyMainView', 'getCorporations');
            this.$subscriptions.add(
                this.sitesService
                    .getSites({
                        structureid: this.structureSelected(),
                        isCorporation: true
                    })
                    .subscribe((corporationsReceived: Site[]) => {
                        this.loadingService.stopLoading('historyMainView', 'getCorporations');
                        this.corporations = corporationsReceived;
                    })
            );
        }
    }

    corporationSelected(): Site | undefined {
        if (this.corporations && this.corporations.some((c) => c.selected)) {
            return this.corporations.find((c) => c.selected);
        }
        return undefined;
    }

    showCounter(): boolean {
        return !isNaN(this.usersCount) && !isNaN(this.disabledUsersCount);
    }

    nextPageUsers(): void {
        const params: any = {
            structureid: this.structureSelected(),
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString()
        };

        if (this.siteSelected()) {
            params.siteid = this.siteSelected().id;
        }
        if (this.corporationSelected()) {
            params.siteid = this.corporationSelected().id;
        }
        if (this.userTypeSelected()) {
            params.role = this.userTypeSelected().shortname;
        }
        if (this.searchTerm !== '') {
            params.search = this.searchTerm;
        }
        if (this.offset === 0) {
            this.getUsersCount(params);
            this.loadingService.startLoading('historyMainView', 'getUsers');
        } else {
            this.loadingService.startLoading('historyNextPage', 'getUsers');
        }

        params.offset = this.offset;
        params.limit = this.limit;

        this.$subscriptions.add(
            this.usersService.getUsers(params).subscribe((usersReceived: User[]) => {
                if (this.offset === 0) {
                    this.loadingService.stopLoading('historyMainView', 'getUsers');
                } else {
                    this.loadingService.stopLoading('historyNextPage', 'getUsers');
                }
                this.users = [...this.users, ...usersReceived];
                this.offset += this.limit;
            })
        );
    }

    getUsersCount(params: any): void {
        this.$subscriptions.add(
            this.usersService
                .getUsers({
                    ...params,
                    count: true
                })
                .subscribe((data: { count: number }) => {
                    this.usersCount = data.count;
                })
        );
        this.$subscriptions.add(
            this.usersService
                .getUsers({
                    ...params,
                    count: true,
                    enabled: false
                })
                .subscribe((data: { count: number }) => {
                    this.disabledUsersCount = data.count;
                })
        );
    }

    exportCSV() {
        this.flashMessageService.flash(`L'export CSV est en cours...`);

        const params: any = {
            structureid: this.structureSelected(),
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString()
        };
        if (this.siteSelected()) {
            params.siteid = this.siteSelected().id;
        }
        if (this.corporationSelected()) {
            params.siteid = this.corporationSelected().id;
        }
        if (this.userTypeSelected()) {
            params.role = this.userTypeSelected().shortname;
        }
        if (this.showDisabledUsers) {
            params.enabled = true;
        }
        if (this.searchTerm !== '') {
            params.search = this.searchTerm;
        }

        this.$subscriptions.add(
            this.usersService.exportHistoryCSV(params).subscribe((csvText: any) => {
                const blob = new Blob(['\ufeff', csvText], {
                    type: 'text/csv;charset=UTF-8;'
                });
                FileSaver.saveAs(blob, 'users_history.csv');
                this.flashMessageService.flash(`L'export CSV est terminé`);
            })
        );
    }

    openUserDialog(user: User): void {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage(
            {
                viewUser: user.id
            },
            '*'
        );
    }

    openUserHistory(user: User): void {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage(
            {
                openUserHistory: user,
                user: user,
                startDate: this.startDate,
                endDate: this.endDate
            },
            '*'
        );
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }
}
