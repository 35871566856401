import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
    AfterViewInit
} from '@angular/core';
import { GraphsService } from '../../../../services/graphs.service';
import { Subscription, Observable } from 'rxjs';

// import {ɵb as InjectionService } from '@swimlane/ngx-charts';

@Component({
    selector: 'app-graphs-assignations',
    templateUrl: './graphs-assignations.component.html',
    styleUrls: ['./graphs-assignations.component.scss'],
    standalone: false
})
export class GraphsAssignationsComponent implements OnInit, OnDestroy, AfterViewInit {
    subscriptions$: Subscription = new Subscription();
    @ViewChild('graphs', {
        read: ViewContainerRef
    })
    viewRef: ViewContainerRef;

    graphData: Observable<any[]>;
    view: any[] = [800, 400];
    // view: any[] = undefined;

    // options
    showLabels = true;
    animations = true;
    xAxis = true;
    yAxis = true;
    showYAxisLabel = false;
    showXAxisLabel = false;
    xAxisLabel = 'Période';
    yAxisLabel = 'Assignations';
    timeline = false;
    activeEntries = [];
    // xAxisTicks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    colorScheme = {
        domain: ['blue', 'red', 'green']
    };

    constructor(
        private graphsService: GraphsService // private injectionService: InjectionService
    ) {}

    ngOnInit() {
        this.graphData = this.graphsService.getAssignationsData();
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }
    ngAfterViewInit() {
        // this.injectionService.setRootViewContainer(this.viewRef);
    }

    xAxisTickFormatting(tick: any) {
        const options: any = {
            year: 'numeric',
            month: 'long'
        };
        const string = new Date(tick).toLocaleDateString('fr-FR', options);
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    onSelect(data): void {}

    onActivate(data): void {}

    onDeactivate(data): void {}

    getName(model: any) {
        const options: any = {
            year: 'numeric',
            month: 'long'
        };
        return `${model.series}, ${new Date(model.name).toLocaleDateString('fr-FR', options)}:`;
    }
}
