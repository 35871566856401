import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoginService } from '../../../services/login.service';
import { LoadingService } from '../../../services/loading.service';

import { StructuresService } from '../../../services/structures.service';
import { Structure } from '../../../structures/structure';
import { SiteService } from '../../../services/site.service';
import { UsersService } from '../../../services/users.service';
import { FlashMessageService } from '../../../services/flash-message.service';
import { DialogService } from '../../../services/dialog.service';

import { User } from '../../../structures/user';
import { Role } from '../../../structures/role';
import { Site } from '../../../structures/site';

import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    standalone: false
})
export class UsersComponent implements OnInit {
    constructor(
        private loginService: LoginService,
        private loadingService: LoadingService,
        private structuresService: StructuresService,
        private sitesService: SiteService,
        private usersService: UsersService,
        private flashMessageService: FlashMessageService,
        private dialogService: DialogService
    ) {}

    $subscriptions: Subscription = new Subscription();

    structures: Array<Structure>;
    users: Array<User> = [];
    usersCount: number;
    limit = 30;
    offset = 0;
    sites: Array<Site>;
    corporations: Array<Site>;
    roles: Array<Role>;
    additionalRoles: Array<Role>;
    userTags: Array<any> = [];
    searchTerm = '';
    userStatus = [
        {
            title: 'Comptes activés (visible par les formateurs)',
            key: 'enabled',
            selected: false
        },
        {
            title: 'Comptes désactivés (non visible par les formateurs)',
            key: 'disabled',
            selected: false
        },
        {
            title: 'Comptes avec date de début',
            key: 'with_start_date',
            selected: false
        },
        {
            title: 'Comptes sans date de début',
            key: 'without_start_date',
            selected: false
        },
        {
            title: 'Comptes avec date de fin',
            key: 'with_end_date',
            selected: false
        },
        {
            title: 'Comptes sans date de fin',
            key: 'without_end_date',
            selected: false
        },
        {
            separator: true
        },
        {
            title: 'Comptes pouvant se connecter',
            key: 'can_connect',
            selected: true
        },
        {
            title: 'Comptes ne pouvant pas se connecter',
            key: 'cannot_connect',
            selected: false
        }
    ];
    reminderCount = [
        {
            title: '0',
            key: '0'
        },
        {
            title: '1',
            key: '1'
        },
        {
            title: '2',
            key: '2'
        },
        {
            title: '3',
            key: '3'
        }
    ];

    previousUserStatus: any;

    ngOnInit(): void {
        this.getStructures();
        this.usersService.getRolesAndTags().subscribe((data: any) => {
            this.roles = data;
        });

        this.usersService.getAdditionalRoles().subscribe((data: any) => {
            this.additionalRoles = data;
        });
        this.previousUserStatus = JSON.parse(JSON.stringify(this.userStatus));

        window.addEventListener('message', (message) => {
            if (message.data.userdeleted) {
                if (this.users.findIndex((user) => user.id === message.data.userdeleted) > -1) {
                    this.users.splice(
                        this.users.findIndex((user) => user.id === message.data.userdeleted),
                        1
                    );
                }
            }
        });
    }

    isNationalAdmin(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }

    isLocalAdmin(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.localAdmin;
        }
    }

    onStructureChange() {
        this.sites = [];
        this.corporations = [];
        this.users = [];
        this.offset = 0;
        if (this.structuresSelected()) {
            this.getSites();
            this.getCorporations();
            this.nextPageUsers();
        }
    }

    onStatusChange() {
        const defaultCategory = [
            'enabled',
            'disabled',
            'with_start_date',
            'without_start_date',
            'with_end_date',
            'without_end_date'
        ];

        let isDefaultFilter = false;
        for (const i in this.previousUserStatus) {
            if (
                defaultCategory.indexOf(this.previousUserStatus[i].key) > -1 &&
                this.previousUserStatus[i].selected !== this.userStatus[i].selected
            ) {
                isDefaultFilter = true;
            }
        }
        for (const i in this.userStatus) {
            if (isDefaultFilter) {
                if (defaultCategory.indexOf(this.userStatus[i].key) === -1) {
                    this.userStatus[i].selected = false;
                }
            } else {
                if (defaultCategory.indexOf(this.userStatus[i].key) > -1) {
                    this.userStatus[i].selected = false;
                }
            }
        }
        this.previousUserStatus = JSON.parse(JSON.stringify(this.userStatus));

        this.onFiltersChange();
    }

    onFiltersChange(): void {
        this.offset = 0;
        this.users = [];
        this.nextPageUsers();
    }

    resetFilters(): void {
        if (this.isNationalAdmin()) {
            this.structures.forEach((st) => {
                st.selected = false;
            });
        }
        this.users = [];
        this.sites = [];
        this.offset = 0;
        this.corporations = [];
        this.roles.forEach((item) => {
            item.selected = false;
        });
        this.reminderCount.map((item: any) => (item.selected = false));
        this.userStatus.forEach((item) => {
            if (
                [
                    'enabled',
                    'disabled',
                    'with_start_date',
                    'without_start_date',
                    'with_end_date',
                    'without_end_date'
                ].indexOf(item.key) > -1
            ) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
        this.onStructureChange();
    }

    resetSearch(): void {
        this.searchTerm = '';
        this.onFiltersChange();
    }

    nextPageUsers(): void {
        const params: any = {
            structureid: this.structuresSelected().join('|')
        };
        if (this.siteSelected()) {
            params.siteid = this.siteSelected().id;
        }
        if (this.corporationSelected()) {
            params.siteid = this.corporationSelected().id;
        }
        if (this.userTypeSelected()) {
            if (this.userTypeSelected().name === 'Référent pédagogique') {
                params.tag = 1;
            } else {
                params.role = this.userTypeSelected().shortname;
            }
        }
        params.mailreminder = this.reminderCount
            .filter((data: any) => data.selected)
            .map((data: any) => data.key)
            .join('|');

        if (!params.mailreminder) {
            delete params.mailreminder;
        }
        if (this.searchTerm !== '') {
            params.search = this.searchTerm;
        }

        this.getStatusParams(params);

        if (this.offset === 0) {
            this.getUsersCount(params);
            this.loadingService.startLoading('usersMainView', 'getUsers');
        } else {
            this.loadingService.startLoading('usersNextPage', 'getUsers');
        }
        params.offset = this.offset;
        params.limit = this.limit;

        this.$subscriptions.add(
            this.usersService.getUsers(params).subscribe((usersReceived: User[]) => {
                if (this.offset === 0) {
                    this.loadingService.stopLoading('usersMainView', 'getUsers');
                } else {
                    this.loadingService.stopLoading('usersNextPage', 'getUsers');
                }

                this.users = [...this.users, ...usersReceived];
                this.offset += this.limit;
            })
        );
    }

    getUsersCount(params: any): void {
        this.loadingService.startLoading('usersMainView', 'getUsersCount');
        this.$subscriptions.add(
            this.usersService
                .getUsersCount({
                    ...params
                })
                .subscribe((data: { count: number }) => {
                    this.loadingService.stopLoading('usersMainView', 'getUsersCount');

                    this.usersCount = data.count;
                })
        );
    }

    getStatusParams(params) {
        if (
            this.userStatus.find((item) => item.key === 'enabled').selected &&
            !this.userStatus.find((item) => item.key === 'disabled').selected
        ) {
            params.enabled = true;
        } else if (
            !this.userStatus.find((item) => item.key === 'enabled').selected &&
            this.userStatus.find((item) => item.key === 'disabled').selected
        ) {
            params.enabled = false;
        }

        if (
            this.userStatus.find((item) => item.key === 'with_start_date').selected &&
            !this.userStatus.find((item) => item.key === 'without_start_date').selected
        ) {
            params.hasstartdate = true;
        } else if (
            !this.userStatus.find((item) => item.key === 'with_start_date').selected &&
            this.userStatus.find((item) => item.key === 'without_start_date').selected
        ) {
            params.hasstartdate = false;
        }

        if (
            this.userStatus.find((item) => item.key === 'with_end_date').selected &&
            !this.userStatus.find((item) => item.key === 'without_end_date').selected
        ) {
            params.hasenddate = true;
        } else if (
            !this.userStatus.find((item) => item.key === 'with_end_date').selected &&
            this.userStatus.find((item) => item.key === 'without_end_date').selected
        ) {
            params.hasenddate = false;
        }

        if (
            this.userStatus.find((item) => item.key === 'can_connect').selected &&
            !this.userStatus.find((item) => item.key === 'cannot_connect').selected
        ) {
            params.canconnect = true;
        } else if (
            !this.userStatus.find((item) => item.key === 'can_connect').selected &&
            this.userStatus.find((item) => item.key === 'cannot_connect').selected
        ) {
            params.canconnect = false;
        }
    }

    createUser(): void {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage(
            {
                viewUser: 0
            },
            '*'
        );
    }

    getStructures(): void {
        this.$subscriptions.add(
            this.structuresService.getStructures().subscribe((data: Structure[]) => {
                this.structures = data.map((structure: Structure) => ({
                    ...structure,
                    key: structure.id,
                    title: structure.name,
                    selected: this.loginService.getUser().structureid === structure.id
                }));
                if (!this.isNationalAdmin()) {
                    setTimeout(() => {
                        this.onStructureChange();
                    });
                }
            })
        );
    }

    structuresSelected(): number[] | undefined {
        if (this.structures && this.structures.find((i) => i.selected)) {
            return this.structures.filter((item) => item.selected).map((st) => st.key);
        }
        return undefined;
    }

    getFullStructuresSelected(): Structure[] | undefined {
        if (this.structures && this.structures.find((i) => i.selected)) {
            return this.structures.filter((item) => item.selected);
        }
        return undefined;
    }

    getSites(): void {
        const params = {
            structureid: this.structuresSelected(),
            isCorporation: false
        };
        this.loadingService.startLoading('usersMainView', 'getSites');
        this.$subscriptions.add(
            this.sitesService.getSites(params).subscribe((sitesReceived: Site[]) => {
                this.loadingService.stopLoading('usersMainView', 'getSites');
                this.sites = this.formatArrayForDropdown(sitesReceived);
            })
        );
    }

    formatArrayForDropdown(array: any[]): any[] {
        return array.map((item) => ({
            ...item,
            title: item.name,
            key: item.id
        }));
    }

    siteSelected(): Site {
        if (this.sites) {
            return this.sites.find((item) => item.selected);
        }
    }

    getCorporations(): void {
        const params = {
            structureid: this.structuresSelected(),
            isCorporation: true
        };
        this.loadingService.startLoading('usersMainView', 'getCorporations');
        this.$subscriptions.add(
            this.sitesService.getSites(params).subscribe((companiesReceived: any[]) => {
                this.loadingService.stopLoading('usersMainView', 'getCorporations');
                this.corporations = this.formatArrayForDropdown(companiesReceived);
            })
        );
    }

    corporationSelected(): Site {
        if (this.corporations) {
            return this.corporations.find((item) => item.selected);
        }
    }

    userTypeSelected(): Role {
        if (this.roles && this.additionalRoles) {
            return this.roles.concat(this.additionalRoles).find((el) => el.selected);
        }
    }

    getUserRoles(user): Role[] {
        if (this.roles) {
            return this.roles.filter((role: Role) => user.roles[role.shortname]);
        }
    }

    getRolesItems() {
        if (this.roles) {
            if (
                this.getFullStructuresSelected().find((structure) => structure.shortname === 'N4S')
            ) {
                return this.roles.concat(this.additionalRoles);
            } else {
                return this.roles
                    .filter((role) => role.shortname !== 'nationalTeacher')
                    .concat(this.additionalRoles);
            }
        } else {
            return [];
        }
    }

    getUserAdditionalRoles(user): Role[] {
        if (this.additionalRoles) {
            return this.additionalRoles.filter(
                (role: Role) => user.additionalRoles[role.shortname]
            );
        }
    }

    showCounter(): boolean {
        return !isNaN(this.usersCount);
    }

    exportCSV() {
        this.flashMessageService.flash(`L'export CSV est en cours...`);

        const params: any = {};
        if (this.structuresSelected()) {
            params.structureid = this.structuresSelected().join('|');
            if (this.structuresSelected().length === this.structures.length) {
                params.export_all = true;
            }
        }
        if (this.siteSelected()) {
            params.siteid = this.siteSelected().id;
        }
        if (this.corporationSelected()) {
            params.siteid = this.corporationSelected().id;
        }
        if (this.userTypeSelected()) {
            if (this.userTypeSelected().name === 'Référent pédagogique') {
                params.tag = 1;
            } else {
                params.role = this.userTypeSelected().shortname;
            }
        }
        if (this.searchTerm !== '') {
            params.search = this.searchTerm;
        }

        this.getStatusParams(params);

        this.usersService.exportCSV(params).subscribe((csvText: any) => {
            const blob = new Blob(['\ufeff', csvText], {
                type: 'text/csv;charset=UTF-8;'
            });
            FileSaver.saveAs(blob, 'users.csv');
            this.flashMessageService.flash(`L'export CSV est terminé`);
        });
    }

    openCSVImportDialog() {
        this.dialogService
            .openImportUsersCSV(this.structuresSelected()[0])
            .subscribe((data: boolean) => {
                if (data) {
                    this.users = [];
                    this.offset = 0;
                    this.nextPageUsers();
                }
            });
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }
}
