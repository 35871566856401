import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Composant gérant l'affichage de la fenêtre d'erreur
 */
@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    standalone: false
})
export class ErrorComponent implements OnInit {
    errorMessage: string;

    constructor(
        public dialogRef: MatDialogRef<ErrorComponent>,
        @Inject(MAT_DIALOG_DATA)
        private errorData: string
    ) {}

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }

    ngOnInit() {
        this.errorMessage = this.errorData
            ? this.errorData
            : 'Une erreur est survenue. Si cette erreur persiste, veuillez contacter votre administrateur.';
    }
}
