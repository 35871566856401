import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoginService } from 'src/app/services/login.service';
import { LoadingService } from 'src/app/services/loading.service';

import { GroupsService } from 'src/app/services/groups.service';
import { DialogService } from 'src/app/services/dialog.service';

import { Group } from 'src/app/structures/group';

@Component({
    selector: 'app-role-groups',
    templateUrl: './role-groups.component.html',
    styleUrls: ['./role-groups.component.scss'],
    standalone: false
})
export class RoleGroupsComponent implements OnInit {
    constructor(
        private loginService: LoginService,
        private loadingService: LoadingService,
        private groupsService: GroupsService,
        private dialogService: DialogService
    ) {}

    subscriptions$: Subscription = new Subscription();

    roleGroups: Array<Group> = [];

    searchTerm = '';
    searchPlaceholder = 'Chercher...';
    offset = 0;

    ngOnInit() {
        this.roleGroups = [];
        this.offset = 0;
        this.getRoleGroups();

        this.groupsService.editedRoleGroup.subscribe(() => {
            this.getRoleGroups();
        });

        this.groupsService.clonedRoleGroup.subscribe((roleGroup) => {
            this.roleGroups.push(roleGroup);
        });

        this.groupsService.deletedRoleGroup.subscribe((roleGroupId) => {
            this.roleGroups.splice(
                this.roleGroups.findIndex((roleGroup) => roleGroup.id === roleGroupId),
                1
            );
        });
    }

    getRoleGroups(): void {
        this.roleGroups = [];
        const params = {
            structureid: this.loginService.getUser().structureid,
            search: this.searchTerm,
            offset: 0
        };
        this.loadingService.startLoading('roleGroupsMainView', 'getRoleGroups');
        this.subscriptions$.add(
            this.groupsService.getRoleGroups(params).subscribe((groupsReceived: Group[]) => {
                this.loadingService.stopLoading('roleGroupsMainView', 'getRoleGroups');
                this.roleGroups = [...this.roleGroups, ...groupsReceived];
            })
        );
    }

    openCreateRoleGroup($event): void {
        $event.stopImmediatePropagation();
        const params = {};
        this.dialogService.openCreateRoleGroup(params).subscribe((groupCreated: Group | false) => {
            if (groupCreated) {
                this.getRoleGroups();
            }
        });
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }
}
