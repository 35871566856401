import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../../services/dialog.service';
import { GroupsService } from '../../../../services/groups.service';
import { FlashMessageService } from '../../../../services/flash-message.service';

import { User } from '../../../../structures/user';
import { Group } from '../../../../structures/group';

@Component({
    selector: 'app-group-entry',
    templateUrl: './group-entry.component.html',
    styleUrls: ['./group-entry.component.scss'],
    standalone: false
})
export class GroupEntryComponent implements OnInit {
    constructor(
        private dialogService: DialogService,
        private groupsService: GroupsService,
        private flashMessageService: FlashMessageService
    ) {}

    subscriptions$: Subscription = new Subscription();

    @Input() public group: Group;

    ngOnInit() {}

    getGroupIcon(): string {
        return this.group.type === 'teacher' ? 'icon-formateurinterne' : 'icon-groupe';
    }

    getUsers(): void {
        if (!this.group.users) {
            this.subscriptions$.add(
                this.groupsService.getGroup(this.group.id).subscribe((groupReceived: Group) => {
                    this.group = {
                        ...this.group,
                        ...groupReceived,
                        users: groupReceived.users.sort((a, b) =>
                            a.lastname.toUpperCase().localeCompare(b.lastname.toUpperCase())
                        )
                    };
                })
            );
        }
    }

    getGroupCounter(): string {
        return `(${this.group.users.length} ${
            this.group.type === 'teacher' ? 'formateurs' : 'apprenants'
        })`;
    }

    getUserIcon(user: User): string {
        if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.nationalTeacher) {
            return 'icon-auteurnational';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        } else if (user.roles.externalCallManager) {
            return 'icon-Telephone';
        }
    }

    sendReminder($event: Event) {
        $event.stopImmediatePropagation();
        this.groupsService.sendEmailReminder(this.group.id).subscribe(() => {
            this.flashMessageService.flash("L'email a été renvoyé à tous les membres du groupe");
        });
    }

    editGroup($event: Event) {
        $event.stopImmediatePropagation();
        this.dialogService.openCreateGroup(this.group).subscribe(() => {
            this.subscriptions$.add(
                this.groupsService.getGroup(this.group.id).subscribe((groupReceived: Group) => {
                    this.group.users = [];
                    this.group = groupReceived;
                })
            );
        });
    }
}
