import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { RoutingModule } from './routing.module';
import { SharedModule } from './shared.module';

import { AdministratorComponent } from '../components/administrator/administrator.component';
import { RightsComponent } from '../components/administrator/rights/rights.component';
import { UsersComponent } from '../components/administrator/users/users.component';
import { UserComponent } from '../components/administrator/users/user/user.component';
import { GroupsComponent } from '../components/administrator/groups/groups.component';

import { GroupEntryComponent } from '../components/administrator/groups/group-entry/group-entry.component';
import { GroupUserEntryComponent } from '../components/administrator/groups/group-entry/group-user-entry/group-user-entry.component';
import { RoleGroupsComponent } from '../components/administrator/role-groups/role-groups.component';
import { RoleGroupEntryComponent } from '../components/administrator/role-groups/role-group-entry/role-group-entry.component';
import { ContactsComponent } from '../components/administrator/contacts/contacts.component';
import { ContactEntryComponent } from '../components/administrator/contacts/contact-entry/contact-entry.component';
import { StructuresComponent } from '../components/administrator/structures/structures.component';
import { SitesComponent } from '../components/administrator/sites/sites.component';
import { HistoryComponent } from '../components/administrator/history/history.component';
import { UnusedElementsComponent } from '../components/administrator/unused-elements/unused-elements.component';
import { NationalAdministrationComponent } from '../components/administrator/national-administration/national-administration.component';
import { LocalDashboardComponent } from '../components/administrator/local-dashboard/local-dashboard.component';
import { NationalDashboardComponent } from '../components/administrator/national-dashboard/national-dashboard.component';
import { NewsEditorComponent } from '../components/administrator/news-editor/news-editor.component';
import { ActivityReportComponent } from '../components/administrator/activity-report/activity-report.component';
import { GraphsComponent } from '../components/administrator/graphs/graphs.component';
import { GraphsAssignationsComponent } from '../components/administrator/graphs/graphs-assignations/graphs-assignations.component';
import { GraphsConnexionsComponent } from '../components/administrator/graphs/graphs-connexions/graphs-connexions.component';
import { GraphsDashboardComponent } from '../components/administrator/graphs/graphs-dashboard/graphs-dashboard.component';
import { GraphsTopActivitiesComponent } from '../components/administrator/graphs/graphs-top-activities/graphs-top-activities.component';

import { AddNewsComponent } from '../dialogs/add-news/add-news.component';
import { AddAvatarComponent } from '../dialogs/add-avatar/add-avatar.component';
import { CreateContactComponent } from '../dialogs/create-contact/create-contact.component';
import { CreateGroupComponent } from '../dialogs/create-group/create-group.component';
import { CreateRoleGroupComponent } from '../dialogs/create-role-group/create-role-group.component';
import { CreateSiteComponent } from '../dialogs/create-site/create-site.component';
import { CreateStructureComponent } from '../dialogs/create-structure/create-structure.component';
import { CsvImportUsersComponent } from '../dialogs/csv-import-users/csv-import-users.component';
import { PreviewImportUsersComponent } from '../dialogs/csv-import-users/preview-import-users/preview-import-users.component';
import { UploadFileComponent } from '../dialogs/upload-file/upload-file.component';
import { RightsExceptionsComponent } from '../dialogs/rights-exceptions/rights-exceptions.component';

import { UsersFilterPipe } from '../pipes/users-filter.pipe';

import { ClickOutsideModule } from '@solidexpert/ng-click-outside';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GraphsActiveUsersComponent } from '../components/administrator/graphs/graphs-active-users/graphs-active-users.component';
import { QueuedReportComponent } from '../components/administrator/activity-report/queued-report/queued-report.component';
import { ImportYpareoComponent } from '../components/administrator/import-ypareo/import-ypareo.component';

@NgModule({
    declarations: [
        AdministratorComponent,
        RightsComponent,
        UsersComponent,
        UserComponent,
        GroupsComponent,
        GroupEntryComponent,
        GroupUserEntryComponent,
        RoleGroupsComponent,
        RoleGroupEntryComponent,
        ContactsComponent,
        ContactEntryComponent,
        StructuresComponent,
        SitesComponent,
        HistoryComponent,
        UnusedElementsComponent,
        NationalAdministrationComponent,
        LocalDashboardComponent,
        NationalDashboardComponent,
        NewsEditorComponent,
        ActivityReportComponent,
        QueuedReportComponent,
        ImportYpareoComponent,
        GraphsComponent,
        GraphsAssignationsComponent,
        GraphsConnexionsComponent,
        GraphsDashboardComponent,
        GraphsTopActivitiesComponent,
        GraphsActiveUsersComponent,
        AddAvatarComponent,
        CreateStructureComponent,
        CreateContactComponent,
        CreateGroupComponent,
        CreateRoleGroupComponent,
        CreateSiteComponent,
        AddNewsComponent,
        UploadFileComponent,
        CsvImportUsersComponent,
        PreviewImportUsersComponent,
        RightsExceptionsComponent,
        UsersFilterPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        SharedModule,
        RoutingModule,
        NgxChartsModule,
        ImageCropperModule,
        EditorModule,
        ClickOutsideModule,
        InfiniteScrollModule
    ],
    exports: [],
    providers: [
        DatePipe,
        {
            provide: TINYMCE_SCRIPT_SRC,
            useValue: 'https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.5.1/tinymce.min.js'
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule {}
