import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
    AfterViewInit
} from '@angular/core';
import { Subscription, Observable } from 'rxjs';

// import {ɵb as InjectionService } from '@swimlane/ngx-charts';

import { GraphsService } from '../../../../services/graphs.service';

@Component({
    selector: 'app-graphs-connexions',
    templateUrl: './graphs-connexions.component.html',
    styleUrls: ['./graphs-connexions.component.scss'],
    standalone: false
})
export class GraphsConnexionsComponent implements OnInit, OnDestroy, AfterViewInit {
    subscriptions$: Subscription = new Subscription();
    @ViewChild('graphs', {
        read: ViewContainerRef
    })
    viewRef: ViewContainerRef;

    graphData: Observable<any[]>;
    isLoading$: Observable<boolean>;
    view: any[] = [800, 400];
    // view: any[] = undefined;

    // options
    showLabels = true;
    animations = true;
    xAxis = true;
    yAxis = true;
    showYAxisLabel = false;
    showXAxisLabel = false;
    xAxisLabel = 'Période';
    yAxisLabel = 'Connexions';
    xAxisTicks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    connexionType: 'uniques' | 'total' = 'uniques';

    colorScheme = {
        domain: ['blue', 'red', 'green', 'orange', 'yellow']
    };

    constructor(
        private graphsService: GraphsService // private injectionService: InjectionService
    ) {
        this.isLoading$ = this.graphsService.isFetchingData$.asObservable();
    }

    ngOnInit() {
        this.graphData = this.graphsService.getConnexionData();

        this.graphsService.getConnexionType().subscribe((type: 'uniques' | 'total') => {
            this.connexionType = type;
        });
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    xAxisTickFormatting(tick: number) {
        const month = new Array();
        month[1] = 'Janvier';
        month[2] = 'Février';
        month[3] = 'Mars';
        month[4] = 'Avril';
        month[5] = 'Mai';
        month[6] = 'Juin';
        month[7] = 'Juillet';
        month[8] = 'Août';
        month[9] = 'Septembre';
        month[10] = 'Octobre';
        month[11] = 'Novembre';
        month[12] = 'Décembre';
        return month[tick];
    }

    ngAfterViewInit() {
        // this.injectionService.setRootViewContainer(this.viewRef);
    }

    onSelect(data): void {}

    onActivate(data): void {}

    onDeactivate(data): void {}
}
