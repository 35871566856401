import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../services/dialog.service';
import { FlashMessageService } from '../../../services/flash-message.service';
import { LoginService } from '../../../services/login.service';
import { LoadingService } from '../../../services/loading.service';
import { SiteService } from '../../../services/site.service';
import { UsersService } from '../../../services/users.service';

import { User } from '../../../structures/user';
import { Site } from '../../../structures/site';

@Component({
    selector: 'app-sites',
    templateUrl: './sites.component.html',
    styleUrls: ['./sites.component.scss'],
    standalone: false
})
export class SitesComponent implements OnInit {
    constructor(
        private loginService: LoginService,
        private loadingService: LoadingService,
        private dialogService: DialogService,
        private flashMessageService: FlashMessageService,
        private siteService: SiteService,
        private usersService: UsersService
    ) {}

    subscriptions: Subscription = new Subscription();

    sites: Array<Site>;

    count: { sites: number; corporations: number };

    displaySites: boolean;
    displayCorporations: boolean;

    searchTerm: string;

    limit: number = 30;
    offset: number = 0;

    // TODO
    // FIX OPEN CREATE

    ngOnInit() {
        this.searchTerm = '';

        this.count = {
            sites: 0,
            corporations: 0
        };

        this.sites = [];

        this.displaySites = true;
        this.displayCorporations = true;

        this.refreshSites();
    }

    initCounts() {
        if (this.displaySites) {
            this.siteService
                .getSitesCount({
                    structureid: this.loginService.getUser().structureid,
                    search: this.searchTerm,
                    isCorporation: false
                })
                .subscribe((sites: any) => {
                    this.count.sites = sites.count;
                });
        }
        if (this.displayCorporations) {
            this.siteService
                .getSitesCount({
                    structureid: this.loginService.getUser().structureid,
                    search: this.searchTerm,
                    isCorporation: true
                })
                .subscribe((corporations: any) => {
                    this.count.corporations = corporations.count;
                });
        }
    }

    getLabel() {
        if (this.displaySites && this.displayCorporations) {
            return `${this.loginService.getUser().localStructure} (${this.count.sites} sites et ${
                this.count.corporations
            } entreprises)`;
        } else if (this.displaySites && !this.displayCorporations) {
            return `${this.loginService.getUser().localStructure} (${this.count.sites} sites )`;
        } else if (!this.displaySites && this.displayCorporations) {
            return `${this.loginService.getUser().localStructure} (${
                this.count.corporations
            } entreprises )`;
        } else {
            return `${this.loginService.getUser().localStructure}`;
        }
    }

    refreshSites() {
        this.offset = 0;
        this.sites = [];
        if (this.displaySites || this.displayCorporations) {
            this.initCounts();
            this.nextPageSites();
        }
    }

    nextPageSites() {
        const params: any = {
            structureid: this.loginService.getUser().structureid,
            offset: this.offset,
            limit: this.limit,
            search: this.searchTerm
        };
        if (this.displaySites && !this.displayCorporations) {
            params.isCorporation = false;
        } else if (!this.displaySites && this.displayCorporations) {
            params.isCorporation = true;
        }
        if (this.offset === 0) {
            this.loadingService.startLoading('sites', 'getSites');
        } else {
            this.loadingService.startLoading('sites', 'nextPageSites');
        }
        this.subscriptions.add(
            this.siteService.getSites(params).subscribe(
                (data: Array<Site>) => {
                    if (this.offset === 0) {
                        this.loadingService.stopLoading('sites', 'getSites');
                    } else {
                        this.loadingService.stopLoading('sites', 'nextPageSites');
                    }
                    this.offset += this.limit;

                    this.sites = this.sites.concat(data);
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('sites');
                }
            )
        );
    }

    toggleDisplaySites() {
        this.displaySites = !this.displaySites;
        this.refreshSites();
    }

    toggleDisplayCorporations() {
        this.displayCorporations = !this.displayCorporations;
        this.refreshSites();
    }

    getSite(site) {
        if (!site.users) {
            this.siteService.getSite(site.id).subscribe((data: Site) => {
                site.users = data.users;
            });
        }
    }

    openCreateSite() {
        const site = {
            structureid: this.loginService.getUser().structureid,
            users: [],
            isCorporation: false
        };
        this.dialogService.openCreateSite(site).subscribe((data: Site) => {
            this.refreshSites();
        });
    }

    openEditSite(event: Event, site: Site) {
        event.stopPropagation();
        this.dialogService.openCreateSite(site).subscribe((siteModified: Site) => {
            if (siteModified) {
                this.refreshSites();
            }
        });
    }

    getUserIcon(user: User): string {
        if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.nationalTeacher) {
            return 'icon-auteurnational';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        } else if (user.roles.externalCallManager) {
            return 'icon-Telephone';
        }
    }

    openUserDialog(user: User): void {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage(
            {
                viewUser: user.id
            },
            '*'
        );
    }

    enableUser(user: User) {
        this.dialogService
            .openConfirmationDialog(
                `Activer l\'utilisateur`,
                `Êtes-vous sûr de vouloir activer l'utilisateur ${user.lastname.toUpperCase()} ${
                    user.firstname
                }?`
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.usersService.enableUser(user).subscribe(() => {
                        user.enabled = true;
                        this.flashMessageService.flash(
                            `<strong>${user.lastname.toUpperCase()} ${
                                user.firstname
                            }</strong> a été activé.`
                        );
                    });
                }
            });
    }

    disableUser(user: User) {
        this.dialogService
            .openConfirmationDialog(
                `Désactiver l\'utilisateur`,
                `Êtes-vous sûr de vouloir désactiver l'utilisateur ${user.lastname.toUpperCase()} ${
                    user.firstname
                }?`
            )
            .subscribe((data: boolean) => {
                if (data) {
                    this.usersService.disableUser(user).subscribe(() => {
                        user.enabled = false;
                        this.flashMessageService.flash(
                            `<strong>${user.lastname.toUpperCase()} ${
                                user.firstname
                            }</strong> a été désactivé.`
                        );
                    });
                }
            });
    }

    isLoading(view) {
        return this.loadingService.isLoading('sites', view);
    }
}
