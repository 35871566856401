import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ConfigService } from 'src/app/services/config.service';
import { LoginService } from 'src/app/services/login.service';
import { StructuresService } from 'src/app/services/structures.service';
import { Structure } from 'src/app/structures/structure';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-local-dashboard',
    templateUrl: './local-dashboard.component.html',
    styleUrls: ['./local-dashboard.component.scss'],
    standalone: false
})
export class LocalDashboardComponent implements OnInit {
    constructor(
        private structuresService: StructuresService,
        private loginService: LoginService,
        private configService: ConfigService,
        private sanitizer: DomSanitizer
    ) {
        this.endDate = new Date();
        this.endDate.setUTCHours(21, 59, 59, 0);
        this.endDate.setDate(this.endDate.getDate() - 1);
        this.startDate = new Date();
        this.startDate.setMonth(this.startDate.getMonth() - 1, this.startDate.getDate() - 1);
        this.startDate.setUTCHours(22, 0, 0, 0);
        this.yesterday = new Date();
        this.yesterday.setDate(this.yesterday.getDate() - 1);
        this.yesterday.setUTCHours(21, 59, 59, 0);
        this.iframeBaseURL = `${this.configService.getEndPoint()}/statistics/local_dashboard?structureid=`;
    }
    subscriptions$: Subscription = new Subscription();
    structures: Array<Structure>;
    startDate: Date;
    endDate: Date;
    yesterday: Date;
    iframeBaseURL: string;
    iframeURL: SafeResourceUrl;
    iframePDFURL: SafeUrl;

    /********************************************************
     *                   COMPONENT LIFECYCLE                *
     ********************************************************/

    ngOnInit(): void {
        this.subscriptions$.add(
            this.structuresService.structures$.subscribe((structuresFromService: Structure[]) => {
                this.structures = structuresFromService.map((st) => ({
                    ...st,
                    selected: this.loginService.getUser().structureid === st.id
                }));
                if (this.structureSelected()) {
                    this.onFiltersChange();
                }
            })
        );
    }

    /**********************************
     *     GESTION DES STRUCTURES     *
     **********************************/

    structureSelected(): number | undefined {
        if (this.structures && this.structures.some((i) => i.selected)) {
            return this.structures.find((item) => item.selected).key;
        }
        return undefined;
    }

    isNationalAdmin(): boolean {
        if (this.loginService.getUser()) {
            return this.loginService.getUser().roles.nationalAdmin;
        }
    }

    /********************************************************
     *                         EVENTS                       *
     ********************************************************/

    onFiltersChange() {
        this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${
                this.iframeBaseURL
            }${this.structureSelected()}&datestart=${this.startDate.toISOString()}&dateend=${this.endDate.toISOString()}`
        );
        this.iframePDFURL = this.sanitizer.bypassSecurityTrustUrl(
            `${
                this.iframeBaseURL
            }${this.structureSelected()}&datestart=${this.startDate.toISOString()}&dateend=${this.endDate.toISOString()}&pdf=1`
        );
    }
}
