import { Component, OnInit, ViewChild } from '@angular/core';

import { GraphsDashboardComponent } from './graphs-dashboard/graphs-dashboard.component';
import { GraphsService } from 'src/app/services/graphs.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-graphs',
    templateUrl: './graphs.component.html',
    styleUrls: ['./graphs.component.scss'],
    standalone: false
})
export class GraphsComponent implements OnInit {
    @ViewChild(GraphsDashboardComponent) dashboard: GraphsDashboardComponent;

    constructor(private graphsService: GraphsService) {
        this.isLoading$ = this.graphsService.isFetchingData$.asObservable();
    }

    isLoading$: Observable<boolean>;

    graphTypeSelected: any;

    graphTypes = [
        {
            title: 'Top 30 des activités les plus utilisées',
            key: 'activities',
            selected: false
        },
        {
            title: 'Nombre de connexions mensuelles',
            key: 'connexions',
            selected: false
        },
        {
            title: "Nombre d'assignations mensuelles",
            key: 'assignations',
            selected: false
        },
        {
            title: "Nombre d'utilisateurs actifs",
            key: 'active-users',
            selected: false
        }
    ];

    ngOnInit() {}

    onGraphTypeSelectedChange(event) {
        this.graphTypeSelected = event.find((item) => item.selected);
    }

    exportToPdf() {
        this.dashboard.generatePDF();
    }

    exportToCSV() {
        this.dashboard.exportCSV();
    }
}
