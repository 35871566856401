import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { NewsService } from '../../../services/news.service';
import { DialogService } from '../../../services/dialog.service';
import { FlashMessageService } from '../../../services/flash-message.service';
import { StructuresService } from '../../../services/structures.service';
import { LoginService } from '../../../services/login.service';
import { LoadingService } from '../../../services/loading.service';

import { Structure } from '../../../structures/structure';
import { News } from '../../../structures/news';

@Component({
    selector: 'app-news-editor',
    templateUrl: './news-editor.component.html',
    styleUrls: ['./news-editor.component.scss'],
    standalone: false
})
export class NewsEditorComponent implements OnInit {
    newsList: Array<News>;
    subscriptions$: Subscription = new Subscription();
    structures: Structure[];
    constructor(
        private newsService: NewsService,
        private dialogService: DialogService,
        private flashMessageService: FlashMessageService,
        private structuresService: StructuresService,
        private loginService: LoginService,
        private loadingService: LoadingService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.loadingService.startLoading('news', 'getSentNews');
        this.getPublishedNews();
        this.loadingService.startLoading('news', 'getStructures');
        this.subscriptions$.add(
            this.structuresService.getStructures().subscribe((structuresList: Structure[]) => {
                this.loadingService.stopLoading('news', 'getStructures');
                this.structures = structuresList;
            })
        );
    }

    /**********
     * GETTERS
     **********/

    getPublishedNewsNumber(): number {
        if (!this.newsList) {
            return 0;
        }
        return this.newsList.length;
    }

    getPublishedNews() {
        this.subscriptions$.add(
            this.newsService.getSentNews().subscribe((newsList: News[]) => {
                this.loadingService.stopLoading('news', 'getSentNews');
                this.newsList = newsList.map((news) => {
                    return {
                        ...news,
                        clean_description: news.description,
                        description: this.sanitizer.bypassSecurityTrustHtml(news.description)
                    };
                });
            })
        );
    }

    getNewsDate(news: News): string {
        if (news.updated_at) {
            return new Date(news.updated_at).toLocaleString();
        } else {
            return new Date(news.created_at).toLocaleString();
        }
    }

    displayNewsStructures(news: any): string {
        if (news.structures.length === 0) {
            return;
        } else if (news.structures.length === 1) {
            if (news.structures[0] === 0) {
                return;
            } else if (!this.structures) {
                return;
            } else {
                return (
                    '(' +
                    news.structures
                        .map(
                            (newsStructureId) =>
                                this.structures.find((st) => st.id === newsStructureId).name
                        )
                        .join(', ') +
                    ')'
                );
            }
        } else {
            return '(' + news.structures.length + ' structures)';
        }
    }

    isNationalAdmin(): boolean {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    /**********
     * SETTERS
     **********/

    addNews() {
        this.dialogService.openAddNews().subscribe((data: any) => {
            if (data.id) {
                this.newsList = [data, ...this.newsList];
                this.flashMessageService.flash('La news a bien été créée.');
            }
        });
    }

    deleteNews(event: Event, news: News) {
        event.stopImmediatePropagation();
        this.dialogService
            .openConfirmationDialog(
                'Supprimer la news',
                'Êtes-vous sûr de vouloir supprimer cette news ?'
            )
            .subscribe((confirmation: boolean) => {
                if (confirmation) {
                    this.subscriptions$.add(
                        this.newsService.deleteNews(news).subscribe((response: 0 | 1) => {
                            if (response) {
                                this.newsList = [
                                    ...this.newsList.filter((current) => current !== news)
                                ];
                                this.flashMessageService.flash('La news a bien été supprimée.');
                            }
                        })
                    );
                }
            });
    }

    editNews(event: Event, news: News, isNewsExpanded: boolean) {
        if (!isNewsExpanded) {
            event.stopImmediatePropagation();
        }
        setTimeout(() => {
            this.dialogService.openAddNews(news).subscribe((data: any) => {
                this.getPublishedNews();
            });
        }, 200);
    }

    isLoading() {
        return this.loadingService.isLoading('news');
    }
}
