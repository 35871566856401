import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../structures/user';

@Pipe({
    name: 'usersFilter',
    standalone: false
})
export class UsersFilterPipe implements PipeTransform {
    transform(usersList: User[], searchTerm: string): any {
        if (!searchTerm) {
            return usersList;
        }
        return usersList.filter((user: User) =>
            `${user.firstname} ${user.lastname.toUpperCase()} ${user.username}`
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        );
    }
}
