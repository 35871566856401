import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, forkJoin } from 'rxjs';

import { DialogService } from '../../../services/dialog.service';
import { FlashMessageService } from '../../../services/flash-message.service';
import { GroupsService } from '../../../services/groups.service';
import { SiteService } from '../../../services/site.service';
import { LoadingService } from '../../../services/loading.service';

import { Group } from '../../../structures/group';
import { Site } from '../../../structures/site';

@Component({
    selector: 'app-unused-elements',
    templateUrl: './unused-elements.component.html',
    styleUrls: ['./unused-elements.component.scss'],
    standalone: false
})
export class UnusedElementsComponent implements OnInit {
    constructor(
        private dialogService: DialogService,
        private flashMessageService: FlashMessageService,
        private groupService: GroupsService,
        private siteService: SiteService,
        private loadingService: LoadingService
    ) {}

    subscriptions: Subscription = new Subscription();

    groups: Array<Group>;
    sites: Array<Site>;
    corporations: Array<Site>;

    currentView: string;

    ngOnInit() {
        const initQuery = [];
        this.sites = [];
        this.corporations = [];
        this.groups = [];

        this.currentView = 'sites';

        initQuery.push(this.siteService.getUnusedSites());
        initQuery.push(this.siteService.getUnusedCorporations());
        initQuery.push(this.groupService.getUnusedGroups());
        this.loadingService.startLoading('unusedElements', 'getData');
        this.subscriptions.add(
            forkJoin(initQuery).subscribe(
                (data: Array<any>) => {
                    this.loadingService.stopLoading('unusedElements', 'getData');
                    this.sites = data[0];
                    this.corporations = data[1];
                    this.groups = data[2];
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('unusedElements');
                }
            )
        );
    }

    isCurrentView(view: string) {
        return this.currentView === view;
    }

    changeCurrentView(view: string) {
        this.currentView = view;
    }

    getDeleteAllLabel() {
        switch (this.currentView) {
            case 'sites': {
                return 'Supprimer les sites inutilisés';
            }
            case 'corporations': {
                return 'Supprimer les entreprises inutilisées';
            }
            case 'groups': {
                return 'Supprimer les groupes inutilisés';
            }
        }
    }

    deleteAllElements() {
        switch (this.currentView) {
            case 'sites': {
                this.subscriptions.add(
                    this.dialogService
                        .openConfirmationDialog(
                            'Supprimer tout les sites',
                            'Etes-vous sûr de vouloir supprimer toutes les entreprises ? Cette action est irréversible'
                        )
                        .subscribe((data: boolean) => {
                            if (data) {
                                this.subscriptions.add(
                                    this.siteService.deleteAllUnusedSites().subscribe(() => {
                                        this.flashMessageService.flash(
                                            'Les sites inutilisés ont été supprimés'
                                        );
                                        this.sites = [];
                                    })
                                );
                            }
                        })
                );
                break;
            }
            case 'corporations': {
                this.subscriptions.add(
                    this.dialogService
                        .openConfirmationDialog(
                            'Supprimer toutes les entreprises',
                            'Etes-vous sûr de vouloir supprimer toutes les entreprises ? Cette action est irréversible'
                        )
                        .subscribe((data: boolean) => {
                            if (data) {
                                this.subscriptions.add(
                                    this.siteService.deleteAllUnusedCorporations().subscribe(() => {
                                        this.flashMessageService.flash(
                                            'Les entreprises inutilisées ont été supprimées'
                                        );
                                        this.corporations = [];
                                    })
                                );
                            }
                        })
                );
                break;
            }
            case 'groups': {
                this.subscriptions.add(
                    this.dialogService
                        .openConfirmationDialog(
                            'Supprimer tout les groupes',
                            'Etes-vous sûr de vouloir supprimer tout les groupes ? Cette action est irréversible'
                        )
                        .subscribe((data: boolean) => {
                            if (data) {
                                this.subscriptions.add(
                                    this.groupService.deleteAllUnusedGroups().subscribe(() => {
                                        this.flashMessageService.flash(
                                            'Les groupes inutilisés ont été supprimés'
                                        );
                                        this.groups = [];
                                    })
                                );
                            }
                        })
                );
            }
        }
    }

    deleteElement(element: any) {
        switch (this.currentView) {
            case 'sites': {
                this.subscriptions.add(
                    this.dialogService
                        .openConfirmationDialog(
                            'Supprimer un site',
                            `Etes-vous sûr de vouloir supprimer le site ${element.name} ? Cette action est irréversible`
                        )
                        .subscribe((data: boolean) => {
                            if (data) {
                                this.subscriptions.add(
                                    this.siteService.deleteUnusedSite(element.id).subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le site ${element.name} a été supprimé`
                                        );
                                        this.sites.splice(
                                            this.sites.findIndex((site) => site.id === element.id),
                                            1
                                        );
                                    })
                                );
                            }
                        })
                );
                break;
            }
            case 'corporations': {
                this.subscriptions.add(
                    this.dialogService
                        .openConfirmationDialog(
                            'Supprimer une entreprise',
                            `Etes-vous sûr de vouloir supprimer l'entreprise ${element.name} ? Cette action est irréversible`
                        )
                        .subscribe((data: boolean) => {
                            if (data) {
                                this.subscriptions.add(
                                    this.siteService.deleteUnusedSite(element.id).subscribe(() => {
                                        this.flashMessageService.flash(
                                            `Le site ${element.name} a été supprimé`
                                        );
                                        this.corporations.splice(
                                            this.corporations.findIndex(
                                                (corporation) => corporation.id === element.id
                                            ),
                                            1
                                        );
                                    })
                                );
                            }
                        })
                );
                break;
            }
            case 'groups': {
                this.subscriptions.add(
                    this.dialogService
                        .openConfirmationDialog(
                            'Supprimer un groupe',
                            `Etes-vous sûr de vouloir supprimer le groupe ${element.name} ? Cette action est irréversible`
                        )
                        .subscribe((data: boolean) => {
                            if (data) {
                                this.subscriptions.add(
                                    this.groupService
                                        .deleteUnusedGroup(element.id)
                                        .subscribe(() => {
                                            this.flashMessageService.flash(
                                                `Le groupe ${element.name} a été supprimé`
                                            );
                                            this.groups.splice(
                                                this.groups.findIndex(
                                                    (group) => group.id === element.id
                                                ),
                                                1
                                            );
                                        })
                                );
                            }
                        })
                );
                break;
            }
        }
    }

    isLoading() {
        return this.loadingService.isLoading('unusedElements');
    }
}
