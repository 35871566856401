import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { ConfigService } from '../../../services/config.service';
import { DialogService } from '../../../services/dialog.service';
import { StructuresService } from '../../../services/structures.service';
import { UsersService } from '../../../services/users.service';

import { Structure } from 'src/app/structures/structure';

import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-resend-password',
    templateUrl: './resend-password.component.html',
    styleUrls: ['./resend-password.component.scss'],
    standalone: false
})
export class ResendPasswordComponent implements OnInit, OnDestroy {
    constructor(
        private formBuilder: UntypedFormBuilder,

        private configService: ConfigService,
        private dialogService: DialogService,
        private structuresService: StructuresService,
        private usersService: UsersService,
        private cookieService: CookieService,
        private router: Router
    ) {}

    subscriptions$: Subscription = new Subscription();

    resendPasswordForm: UntypedFormGroup;

    errorMessage: string;
    successMessage: string;
    informationMessage: string;

    structures: Structure[];

    ngOnInit() {
        this.informationMessage = this.configService.getConfig().passwordInformationMessage;
        this.resendPasswordForm = this.formBuilder.group({
            username: ['', Validators.required]
        });

        if (this.cookieService.get('username')) {
            this.resendPasswordForm.get('username').setValue(this.cookieService.get('username'));
        }
        this.subscriptions$.add(
            this.structuresService.getStructures().subscribe((data: Structure[]) => {
                this.structures = data.map((structure: Structure) => ({
                    key: structure.id,
                    title: structure.name,
                    selected: structure.shortname === this.cookieService.get('config')
                }));
                this.structures.push({
                    title: 'Administration nationale',
                    key: 0,
                    selected:
                        this.cookieService.get('localStructure') === 'Administration nationale'
                });
            })
        );

        this.calculateViewportHeight();
        window.addEventListener('resize', this.calculateViewportHeight);
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    isDarkTheme() {
        return this.cookieService.get('dark') === 'true';
    }

    showDropdownStructure() {
        return this.configService.getConfig().showPasswordStructure;
    }

    calculateViewportHeight() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    getHeaderText() {
        return this.configService.getConfig().header_text;
    }

    getImageTop() {
        return this.configService.getConfig().left_image_top;
    }

    getImageBottom() {
        return this.configService.getConfig().left_image_bot;
    }

    getVersion() {
        return this.configService.getConfig().version;
    }

    getRightSidePanelBackground(): {
        'background-image': string;
    } {
        return {
            'background-image': `url(${this.configService.getConfig().right_image})`
        };
    }

    canResendPassword(): boolean {
        return this.resendPasswordForm.valid && this.getSelectedStructure() !== undefined;
    }

    checkResendPassword() {
        if (this.canResendPassword()) {
            this.resendPassword();
        } else if (this.getSelectedStructure() === undefined) {
            this.errorMessage = 'Veuillez sélectionner une structure';
        } else if (!this.resendPasswordForm.value.username) {
            this.errorMessage = 'Veuillez renseigner votre identifiant';
        }
    }

    resendPassword() {
        this.usersService
            .forgotPassword(this.resendPasswordForm.value.username, this.getSelectedStructure().key)
            .subscribe(
                (data: any) => {
                    this.errorMessage = '';
                    this.successMessage =
                        'Un mail permettant de réinitialiser votre mot de passe vient de vous être envoyé.';
                },
                (error: HttpErrorResponse) => {
                    this.errorMessage = error.error.userMessage;
                }
            );
    }

    getSelectedStructure(): Structure | undefined {
        if (this.structures) {
            return this.structures.find((s) => s.selected);
        }
    }

    goToLogin() {
        this.router.navigate(['login']);
    }

    goToCGU() {
        this.router.navigate(['cgu']);
    }

    openDiagnostic() {
        this.dialogService.openDiagnostic(false);
    }

    getAnchoredLogoURL() {
        return this.configService.getConfig().anchored_logo_url;
    }
}
