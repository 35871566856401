import { Component, OnInit } from '@angular/core';
import { signal } from '@angular/core';

import { YpareoService } from '../../../services/ypareo.service';
import { LoginService } from '../../../services/login.service';

interface Setting {
    key: string;
    value: string;
    description: string;
    setting_type: string;
    metadata: any;
}

@Component({
    selector: 'app-import-ypareo',
    templateUrl: './import-ypareo.component.html',
    styleUrls: ['./import-ypareo.component.scss'],
    standalone: false
})
export class ImportYpareoComponent implements OnInit {
    readonly panelOpenState = signal(false);

    processing: boolean = false;
    success: true | false | null = null;
    error: string = '';

    ypareoApiUrl: string = '';
    ypareoAuthToken: string = '';

    successMessage: string = '';
    errorMessage: string = '';

    webservicesConfigurationValidated: boolean = false;

    private _storedSettings: Setting[] = [];

    constructor(private loginService: LoginService, private ypareoService: YpareoService) {
        // alert(this.loginService.getUser().structureid)

        const structureId = this.loginService.getUser().structureid;
        this.ypareoService.fetchSettings(structureId).subscribe((data) => {
            this._storedSettings = data;

            this.ypareoApiUrl = this.getStoredSetting('ypareo_base_url').value || '';
            this.ypareoAuthToken = this.getStoredSetting('ypareo_token').value || '';

            this.webservicesConfigurationValidated =
                this.ypareoApiUrl !== '' && this.ypareoAuthToken !== ''; // Selon Noël

            return data;
        });
    }

    isViewVisible(view: string): { visible: boolean; collapsed: boolean } {
        console.log('isViewVisible', view);
        if (view === 'test_webservices_ypareo') {
            // Tant qu’aucune configuration n’est validée et testée le volet apparait ouvert pour les administrateurs
            if (this.loginService.getUser().roles.localAdmin) {
                console.log('isViewVisible', {
                    visible: true,
                    collapsed: false
                });
                return {
                    visible: true,
                    collapsed: false
                };
            }
        } else if (view === 'no_configuration_found') {
            // Tant qu’aucune configuration n’est validée les gestionnaires de comptes ne voient qu’un texte les incitant à prendre contact avec un admin local de leur pôle
            if (
                this.webservicesConfigurationValidated === false &&
                this.loginService.getUser().additionalRoles.accountManager
            ) {
                console.log('isViewVisible', {
                    visible: true,
                    collapsed: false
                });
                return {
                    visible: true,
                    collapsed: false
                };
            }
        } else if (view === 'import_users') {
            // Quand la configuration est validée on fait apparaitre le volet « import d’utilisateurs » aux administrateurs locaux. Dès lors, le volet apparaitra « replié » par défaut.
            if (
                this.webservicesConfigurationValidated === true &&
                this.loginService.getUser().roles.localAdmin
            ) {
                console.log('isViewVisible', {
                    visible: true,
                    collapsed: true
                });
                return {
                    visible: true,
                    collapsed: true
                };
            }
            // Quand la configuration est validée on fait apparaitre le volet « import d’utilisateurs » aux gestionnaires de comptes. Le texte précédemment affiché est masqué dès lors qu’une configuration est validée.
            else if (
                this.webservicesConfigurationValidated === true &&
                this.loginService.getUser().additionalRoles.accountManager
            ) {
                console.log('isViewVisible', {
                    visible: true,
                    collapsed: false
                });
                return {
                    visible: true,
                    collapsed: false
                };
            }
        }
        console.log('isViewVisible', {
            visible: false,
            collapsed: false
        });
        return {
            visible: false,
            collapsed: false
        };
    }

    ngOnInit() {}

    getStoredSetting(key: string): Setting | null {
        // Check if _storedSettings is empty or undefined
        if (!this._storedSettings || this._storedSettings.length === 0) {
            debugger;
        }
        const setting = this._storedSettings.find((item: Setting) => {
            return item.key === key;
        });
        return setting ? setting : null;
    }

    testYpareoImport(event: Event): void {
        this.processing = true;
        this.success = null;
        this.error = '';

        this.ypareoService
            .testYpareoImport()
            .subscribe((data: { success: boolean; error?: string }) => {
                this.success = data?.success === true;
                this.success = true;
                if (!this.success) {
                    this.error = data?.error ?? 'Aucune information';
                }

                this.processing = false;
            });
    }

    isTestProcessing(): boolean {
        return this.processing;
    }

    isTestValid(): boolean {
        return this.success === true;
    }

    isTestInvalid(): boolean {
        return this.success === false;
    }

    resetTest(): void {
        this.success = null;
    }

    saveTestSettings(): void {
        const structureId = this.loginService.getUser().structureid;
        const settings = {
            ypareo_base_url: this.ypareoApiUrl,
            ypareo_token: this.ypareoAuthToken
        };

        this.ypareoService.saveSettings(structureId, settings).subscribe({
            next: (response) => {
                this.successMessage = 'Les modifications ont été enregistrées avec succès.';
                this.errorMessage = '';
                console.log('Settings saved successfully', response);
            },
            error: (err) => {
                this.errorMessage = "Erreur lors de l'enregistrement des modifications.";
                this.successMessage = '';
                console.error('Error saving settings', err);
            }
        });
    }

    selectCheckbox(Event): void {
        alert('Checkbox');
    }
}
