import { Component, Input } from '@angular/core';

import { ContactService } from '../../../../services/contact.service';
import { LoginService } from '../../../../services/login.service';
import { FlashMessageService } from '../../../../services/flash-message.service';
import { DialogService } from '../../../../services/dialog.service';

import { Role } from '../../../../structures/role';
import { Contact } from '../../../../structures/contact';

@Component({
    selector: 'app-contact-entry',
    templateUrl: './contact-entry.component.html',
    styleUrls: ['./contact-entry.component.scss'],
    standalone: false
})
export class ContactEntryComponent {
    constructor(
        private contactService: ContactService,
        private dialogService: DialogService,
        private flashMessageService: FlashMessageService
    ) {}

    @Input() contact: Contact;
    @Input() roles: Role[];

    editContact(): void {
        this.dialogService.openCreateContact(this.contact).subscribe((data: boolean) => {
            if (data) {
                this.flashMessageService.flash('Le contact a été modifié');
            }
        });
    }

    cloneContact() {
        this.contactService.cloneContact(this.contact.id).subscribe((data) => {
            this.contactService.emitContactCreated(JSON.parse(JSON.stringify(data)));
            this.flashMessageService.flash('Le contact a été dupliqué');
        });
    }

    hideContact() {
        this.contactService.toggleContactVisibility(this.contact.id).subscribe(() => {
            this.contact.hidden = true;
            this.flashMessageService.flash('Le contact a été masqué');
        });
    }

    showContact() {
        this.contactService.toggleContactVisibility(this.contact.id).subscribe(() => {
            this.contact.hidden = false;
            this.flashMessageService.flash('Le contact a été démasqué');
        });
    }

    deleteContact() {
        const title = 'Confirmer la suppression';
        const body = 'Êtes vos sûr de vouloir supprimer ce contact ?';
        this.dialogService.openConfirmationDialog(title, body).subscribe((data: boolean) => {
            if (data) {
                this.contactService.deleteContact(this.contact.id).subscribe(() => {
                    this.contactService.emitContactDeleted(this.contact.id);
                    this.flashMessageService.flash('Le contact a été supprimé');
                });
            }
        });
    }
}
