<p class="tm-text-center tm-color-grey" *ngIf="isViewVisible('no_configuration_found').visible">
    <em
        >Aucune configuration des webservices ypaero trouvée.<br />
        Veuillez vous rapprocher de l'administration local d'easi.</em
    >
</p>

<hr />

<mat-accordion *ngIf="isViewVisible('test_webservices_ypareo').visible">
    <mat-expansion-panel
        [expanded]="!isViewVisible('test_webservices_ypareo').collapsed"
        (opened)="panelOpenState.set(true)"
        (closed)="panelOpenState.set(false)"
    >
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h1 class="tm-text-center">Configuration webservices ypareo</h1>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="tm-description tm-text-center">
            <p>Veuillez saisir l'adresse des webservices ypareo et la clé d'identification</p>
        </div>

        <div class="tm-form">
            <form (ngSubmit)="testYpareoImport($event)" #ypareoForm="ngForm">
                <div>
                    <p style="color: red">
                        Le WS de test n'est pas encore disponible ->
                        https://tasks.internal.cblue.be/issues/770877
                    </p>
                    <p *ngIf="successMessage" class="alert alert-success">
                        {{ successMessage }}
                    </p>

                    <!-- Message d'erreur -->
                    <p *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </p>
                </div>
                <div class="tm-flex tm-flex-row tm-justify-between tm-align-center tm-gap-2">
                    <div class="tm-flex tm-flex-column tm-gap">
                        <div class="tm-flex tm-flex-row tm-justify-between tm-align-center tm-gap">
                            <div class="tm-field-icon">
                                <span class="icon icon-UserYPareo tm-icon"></span>
                            </div>
                            <div class="tm-field-input">
                                <input
                                    type="text"
                                    name="ypareo_url_webservices"
                                    placeholder="Url des webservices YPAREO"
                                    class="tm-input-field"
                                    required
                                    [(ngModel)]="ypareoApiUrl"
                                    (ngModelChange)="resetTest()"
                                />
                            </div>
                            <div class="tm-field-icon">
                                <button class="tm-button">
                                    <span
                                        class="icon icon-Aide tm-icon"
                                        [tooltip]="
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                                        "
                                        [tooltipColor]="'red'"
                                        [tooltipPosition]="'above'"
                                    ></span>
                                </button>
                            </div>
                        </div>

                        <div class="tm-flex tm-flex-row tm-justify-between tm-align-center tm-gap">
                            <div class="tm-field-icon">
                                <span class="icon icon-Restreindre tm-icon"></span>
                            </div>
                            <div class="tm-field-input">
                                <input
                                    type="text"
                                    name="ypareo_auth_token"
                                    placeholder="Token d'identification (auth.token)"
                                    class="tm-input-field"
                                    required
                                    [(ngModel)]="ypareoAuthToken"
                                    (ngModelChange)="resetTest()"
                                />
                            </div>
                            <div class="tm-field-icon">
                                <button class="tm-button">
                                    <span
                                        class="icon icon-Aide tm-icon"
                                        [tooltip]="
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                                        "
                                        [tooltipColor]="'red'"
                                        [tooltipPosition]="'above'"
                                    ></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="tm-column-expand">
                        <div class="tm-flex tm-flex-row tm-align-center tm-gap">
                            <button
                                type="submit"
                                class="tm-button"
                                [class.tm-button--processing]="isTestProcessing()"
                                [class.tm-button--success]="isTestValid()"
                                [class.tm-button--error]="isTestInvalid()"
                                [disabled]="
                                    isTestProcessing() ||
                                    isTestValid() ||
                                    isTestInvalid() ||
                                    !ypareoForm.valid
                                "
                            >
                                <span class="tm-button-text">Tester</span>
                                <span *ngIf="isTestProcessing()" class="tm-loading-icon"></span>
                            </button>
                            <div id="tm-testYpareoImport-results">
                                <button *ngIf="isTestValid()" class="tm-button tm-color-success">
                                    <span class="icon icon-Valide tm-icon"></span>
                                </button>
                                <button *ngIf="isTestInvalid()" class="tm-button tm-color-error">
                                    <span
                                        class="icon icon-Fermerdetails tm-icon"
                                        [tooltip]="error"
                                        [tooltipPosition]="'above'"
                                    ></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="tm-button tm-button--red" (click)="saveTestSettings()">
                            Enregistrer les modifications
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<div
    class="tm-flex tm-flex-row tm-justify-between tm-gap-2 tm-section"
    *ngIf="isViewVisible('import_users').visible"
>
    <div><span id="tm-nb-users-selected">X</span> utilisateurs sélectionnés</div>
    <div class="tm-flex tm-flex-column tm-gap">
        <div class="tm-flex tm-gap-0.5">
            <div
                *ngFor="let checkbox of ['Nom', 'Prénom', 'Email', 'Groupe easi', 'Groupe ypareo']"
            >
                <mat-checkbox (change)="selectCheckbox($event)" [value]="checkbox">
                    {{ checkbox }}
                </mat-checkbox>
            </div>
        </div>
        <div>
            <div class="search-container">
                <input type="search" class="search" placeholder="Chercher..." />
                <span class="search-icons">
                    <span class="icon-search"></span>
                    <span class="icon-Fermerdetails"></span>
                </span>
            </div>

            <!--                    <div class="search-container">-->
            <!--                        <input-->
            <!--                            type="search"-->
            <!--                            class="search"-->
            <!--                            [(ngModel)]="searchTerm"-->
            <!--                            placeholder="Chercher..."-->
            <!--                            (keyup.enter)="onFiltersChange()"-->
            <!--                        />-->
            <!--                        <span class="search-icons">-->
            <!--                            <span class="icon-search" (click)="onFiltersChange()"></span>-->
            <!--                            <span-->
            <!--                                class="icon-Fermerdetails"-->
            <!--                                *ngIf="searchTerm"-->
            <!--                                (click)="resetSearch()"-->
            <!--                            ></span>-->
            <!--                        </span>-->
            <!--                    </div>-->
        </div>
    </div>
    <div class="tm-flex tm-flex-column tm-align-center tm-gap-0.5">
        <div>
            <button class="tm-button">Importer la sélection</button>
        </div>
        <div>
            <button class="tm-button">Exporter en CSV</button>
        </div>
    </div>
</div>

<hr />
