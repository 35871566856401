import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { UsersService } from 'src/app/services/users.service';
import { SiteService } from 'src/app/services/site.service';
import { FlashMessageService } from 'src/app/services/flash-message.service';

import { User } from '../../structures/user';
import { Site } from '../../structures/site';

@Component({
    selector: 'app-create-site',
    templateUrl: './create-site.component.html',
    styleUrls: ['./create-site.component.scss'],
    standalone: false
})
export class CreateSiteComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public site: Site,
        public dialogRef: MatDialogRef<CreateSiteComponent>,
        private usersService: UsersService,
        private siteService: SiteService,
        private flashMessageService: FlashMessageService
    ) {}

    @Output()
    openErrorDialog: EventEmitter<string> = new EventEmitter();

    subscriptions: Subscription = new Subscription();

    potentialUsers: Array<User> = [];
    potentialUsersSearch = '';
    potentialUsersCurrentPage = 0;

    memberUsersSearch = '';

    ngOnInit() {
        if (this.site.id) {
            this.subscriptions.add(
                this.siteService.getSite(this.site.id).subscribe((data: Site) => {
                    this.site.users = data.users;
                    this.site.isCorporation = data.isCorporation;
                    this.nextPage();
                })
            );
        } else {
            this.nextPage();
        }
    }

    getTitle() {
        if (!this.site.id) {
            return !this.site.isCorporation ? 'Créer un site' : 'Créer une entreprise';
        } else {
            return !this.site.isCorporation
                ? 'Mettre un jour le site'
                : "Mettre à jour l'entreprise";
        }
    }

    getSiteMembersCount(): number {
        if (this.site.users) {
            return this.site.users.length;
        }
    }

    searchPotentialUsers(): void {
        this.potentialUsers = [];
        this.potentialUsersCurrentPage = 0;
        this.nextPage();
    }

    nextPage() {
        const params = {
            structureid: this.site.structureid,
            search: this.potentialUsersSearch,
            limit: 30,
            offset: this.potentialUsersCurrentPage * 30,
            role:
                'learner|internalTeacher|externalTeacher|corporationTeacher|siteTeacher|nationalTeacher'
        };
        this.subscriptions.add(
            this.usersService.getUsers(params).subscribe((data: User[]) => {
                this.potentialUsers = [
                    ...this.potentialUsers,
                    ...data.filter(
                        (user: User) =>
                            this.site.users.findIndex((userInSite) => userInSite.id === user.id) ===
                            -1
                    )
                ];
            })
        );
        this.potentialUsersCurrentPage += 1;
    }

    getUserIcon(user: User): string {
        if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.nationalTeacher) {
            return 'icon-auteurnational';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.externalCallManager) {
            return 'icon-Telephone';
        }
    }

    addUserToSite(user: User): void {
        this.site.users.push(user);
        this.site.users.sort((a: User, b: User) =>
            `${a.lastname.toUpperCase()} ${a.firstname}`.localeCompare(
                `${b.lastname.toUpperCase()} ${b.firstname}`
            )
        );
        this.potentialUsers = this.potentialUsers.filter((u) => u !== user);
    }

    removeUserFromSite(user: User): void {
        this.potentialUsers.push(user);
        this.potentialUsers.sort((a: User, b: User) =>
            `${a.lastname.toUpperCase()} ${a.firstname}`.localeCompare(
                `${b.lastname.toUpperCase()} ${b.firstname}`
            )
        );
        this.site.users = this.site.users.filter((u) => u !== user);
    }

    createSite() {
        if (!this.site.id) {
            this.subscriptions.add(
                this.siteService.createSite(this.site).subscribe((data: any) => {
                    this.flashMessageService.flash(`Le site ${this.site.name} a été créé`);
                    this.dialogRef.close(data);
                })
            );
        } else {
            this.subscriptions.add(
                this.siteService.updateSite(this.site).subscribe((data: any) => {
                    this.flashMessageService.flash(`Le site ${this.site.name} a été mis à jour`);
                    this.dialogRef.close(data);
                })
            );
        }
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }
}
